import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import Api from 'api/agent';
import NavMenu from 'components/layout/NavMenu';
import { toast } from 'react-toastify';
import {
  Table, Container, Input, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import DeletePromptButton from 'components/common/DeletePromptButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportData } from 'models/report';
import { KeyValuePair } from 'models/keyValuePair';
import { history } from '../..';

const ReportBuilderList: React.FC = () => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<ReportData[]>([]);
  const [reportAvailableTypes, setReportAvailableTypes] = useState<KeyValuePair<string, number>[]>([]);
  const [reportTypeChanged, setReportTypeChanged] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editReportId, setEditReportId] = useState(0);

  const toggleConfirmationModal = () => setConfirmationModal(!confirmationModal);

  const openConfirmationModal = (reportId = 0) => {
    setEditReportId(reportId);
    setConfirmationModal(true);
  };

  const backToProfile = () => {
    window.location.href = assessmentConfiguration.returnUrl;
  };

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const setReportForType = (index: number, value: string) => {
    let parsedType: number | undefined = parseInt(value, 10);
    if (Number.isNaN(parsedType)) {
      parsedType = undefined;
    }
    const newState = reports.map((item, i) => {
      if (i === index) {
        return { ...item, forAssessmentType: parsedType };
      }
      return item;
    });
    setReports(newState);
    setReportTypeChanged(true);
  };

  const deleteReport = (index: number) => {
    const newState = [...reports];
    newState.splice(index, 1);
    setReports(newState);
  };

  const addNewReport = () => {
    const newItems = [...reports];
    newItems.splice(reports.length, 0, {
      id: 0,
      availableTypes: reportAvailableTypes,
    });
    Api.Assessments.saveReportConfigs(newItems)
      .then((data) => {
        setReports(data.reports);
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('An error occurred while adding new report!');
        }
      });
  };

  const saveChanges = (reportId = 0) => {
    setConfirmationModal(false);
    setReportTypeChanged(false);
    Api.Assessments.saveReportConfigs(reports)
      .then((data) => {
        if (reportId > 0) {
          history.push(`/report-builder/${reportId}`);
        } else {
          setReports(data.reports);
          toast.success('Saved successfully!');
        }
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('An error occurred while saving!');
        }
      });
  };

  const loadReports = () => {
    setConfirmationModal(false);
    setReportTypeChanged(false);
    setLoading(true);
    Api.Assessments.getReportConfigs()
      .then((data) => {
        setReports(data.reports);
        setReportAvailableTypes(data.availableTypes);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.data?.message) {
          toast.error(error.data.message);
        }
      });
  };

  useEffect(() => {
    loadReports();
  }, [setReports, setReportAvailableTypes]);

  return (
    <>
      <NavMenu buttonText="Back to Profile" buttonCallback={backToProfile} />
      <div className="report-builder-list" style={{ paddingTop: getNavbarHeight() }}>
        <Container>
          <div className="report-builder-list-controls">
            <Button disabled={loading} onClick={() => addNewReport()}>Add New Report</Button>
            <Button disabled={loading} onClick={() => (reportTypeChanged ? openConfirmationModal() : saveChanges())} className="mr-2">Save Changes</Button>
          </div>
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>For Type</th>
                <th aria-label="Controls" />
              </tr>
            </thead>
            {loading
              ? (
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              )
              : (
                <tbody>
                  {reports.map((report, index) => (
                    <tr key={report.id}>
                      <th scope="row" className="align-middle">{Math.abs(report.id)}</th>
                      <td>
                        <Input
                          type="select"
                          onChange={(e) => setReportForType(index, e.target.value)}
                          value={report.forAssessmentType !== null ? report.forAssessmentType : undefined}
                        >
                          {reportAvailableTypes.map((item) => (
                            <option
                              key={item.value}
                              value={item.value}
                            >
                              {item.key}
                            </option>
                          ))}
                        </Input>
                      </td>
                      <td className="d-flex flex-row-reverse">
                        <DeletePromptButton disabled={loading} subject="report" deleteAction={() => deleteReport(index)}>
                          <FontAwesomeIcon icon={['fas', 'trash-alt']} className="mr-2" />
                          Delete
                        </DeletePromptButton>
                        <Button disabled={loading} className="mr-2" onClick={() => (reportTypeChanged ? openConfirmationModal(report.id) : saveChanges(report.id))}>
                          <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2" />
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
          </Table>
        </Container>
      </div>
      <Modal backdrop="static" size="lg" isOpen={confirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader toggle={toggleConfirmationModal}>
          Are you sure you want to continue?
        </ModalHeader>
        <ModalBody>
          If you update this report&apos;s
          <strong> &quot;For Type&quot; </strong>
          field, it will convert all the child pages and sections to the new Type you have chosen.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => loadReports()}>Revert</Button>
          {' '}
          <Button color="primary" onClick={() => saveChanges(editReportId)}>Continue</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReportBuilderList;
