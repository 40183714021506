import React from 'react';
import { useRecoilValue } from 'recoil';
import { currentPageAssessments as currentPageAssessmentsSelector } from 'state/selectors';
import { AssessmentQuestionModel, AssessmentQuestionType } from 'models/assessment';
import LikertScale from './LikertScale';
import AmountScale from './AmountScale';
import DichotomyScale from './DichotomyScale';

interface Props {
  assessmentQuestion: AssessmentQuestionModel;
  optionClickCallback: () => void;
  pageChangedCallback?: () => void;
}

const Assessment: React.FC<Props> = ({
  assessmentQuestion, optionClickCallback, pageChangedCallback, children,
}) => {
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);

  const generateLikertScaleQuestion = () => {
    const isActive = assessmentQuestion.active || currentPageAssessments.every((a) => !a.active);
    return (
      <div
        id={`assessment-${assessmentQuestion.id}`}
        className={`assessment-container ${isActive ? 'active' : 'inactive'}`}
        style={children ? { position: 'relative' } : {}}
      >
        {children}
        <p className="assessment-title">{assessmentQuestion.title}</p>
        <div className="assessment-answer">
          <LikertScale assessmentQuestion={assessmentQuestion} optionClickCallback={optionClickCallback} pageChangedCallback={pageChangedCallback} />
        </div>
      </div>
    );
  };

  const generateAmountQuestion = () => (
    <div
      id={`assessment-${assessmentQuestion.id}`}
      className="assessment-container"
      style={children ? { position: 'relative' } : {}}
    >
      {children}
      <AmountScale assessmentQuestion={assessmentQuestion} optionClickCallback={optionClickCallback} pageChangedCallback={pageChangedCallback} />
    </div>
  );

  const generateDichotomyQuestion = () => (
    <div
      id={`assessment-${assessmentQuestion.id}`}
      className="assessment-container"
      style={children ? { position: 'relative' } : {}}
    >
      {children}
      <DichotomyScale assessmentQuestion={assessmentQuestion} optionClickCallback={optionClickCallback} pageChangedCallback={pageChangedCallback} />
    </div>
  );

  const resolveQuestion = () => {
    switch (assessmentQuestion.questionType) {
      case AssessmentQuestionType.AgreementScale:
      case AssessmentQuestionType.Ranking:
      case AssessmentQuestionType.SimilarityScale:
        return generateLikertScaleQuestion();
      case AssessmentQuestionType.Amount:
        return generateAmountQuestion();
      case AssessmentQuestionType.Dichotomy:
        return generateDichotomyQuestion();
      default:
        return generateLikertScaleQuestion();
    }
  };

  return (
    resolveQuestion()
  );
};

Assessment.defaultProps = {
  pageChangedCallback: undefined,
};

export default Assessment;
