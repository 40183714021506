import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  showReportTypeLite as showReportTypeLiteAtom,
  reportType as reportTypeAtom,
} from 'state/atoms';
import AssessmentType from 'components/assessment-result/AssessmentType';
import AssessmentGraph from 'components/assessment-result/AssessmentGraph';

const ReportTypeContainer: React.FC = () => {
  const [showReportTypeLite, setShowReportTypeLite] = useRecoilState(showReportTypeLiteAtom);
  const reportType = useRecoilValue(reportTypeAtom);

  const reportTypeToggle = () => {
    setShowReportTypeLite(!showReportTypeLite);
  };

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');
    return footer?.offsetHeight;
  };

  return (
    <div className="report-type-container" style={{ width: showReportTypeLite ? 50 : 240, paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
      <div className={`${(showReportTypeLite ? '' : ' d-none')}`}>
        <div className="report-type-lite">
          <div className="report-type-lite-header">
            <Button
              outline
              color="secondary"
              size="sm"
              className="report-type-lite-header-button"
              onClick={() => reportTypeToggle()}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            </Button>
            <FontAwesomeIcon
              icon={[reportType.iconPrefix ?? 'fas', reportType.iconName ?? 'award']}
              className={`report-type-lite-header-icon text-white ${reportType.iconColor ? '' : 'brand-primary-bg'}`}
              style={reportType.iconColor ? { backgroundColor: reportType.iconColor } : {}}
            />
          </div>
        </div>
      </div>
      <div className={`${(showReportTypeLite ? ' d-none' : '')}`}>
        <div className="report-type">
          <div className="report-type-header">
            <Button
              outline
              color="secondary"
              size="sm"
              className="report-type-header-button"
              onClick={() => reportTypeToggle()}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </Button>
            <div>
              <span className="report-type-header-text">Your Type</span>
            </div>
          </div>
          <AssessmentType />
          <AssessmentGraph location="type-container" />
        </div>
      </div>
    </div>
  );
};

export default ReportTypeContainer;
