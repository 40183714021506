import React from 'react';
import { ReportBuilderTool as ReportBuilderToolModel } from 'models/reportBuilderTool';

interface Props {
  tool: ReportBuilderToolModel;
}

const ReportBuilderTool: React.FC<Props> = ({ tool }) => (
  <div className="tool">
    <div className="d-flex justify-content-center align-items-center">
      <span>{tool.name}</span>
    </div>
  </div>
);

export default ReportBuilderTool;
