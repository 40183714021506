import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reportType as reportTypeAtom } from 'state/atoms';
import { useRecoilValue } from 'recoil';
import TooltipComponent from 'components/common/TooltipComponent';
import VenoBox from 'venobox/dist/venobox';

const AssessmentType: React.FC = () => {
  const reportType = useRecoilValue(reportTypeAtom);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let venobox: any;
    if (reportType.videoUrl) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      venobox = new VenoBox({
        selector: '#assessment-type-link',
      });
    }

    return () => {
      venobox?.close();
    };
  }, [reportType.videoUrl]);

  return (
    <>
      <a href={reportType.videoUrl} data-vbtype="video" id="assessment-type-link" className={`assessment-type-container ${reportType.videoUrl ? '' : 'disabled'}`}>
        <div className="assessment-type-name">
          {reportType.name?.map((item) => <span key={`assessment-type-${item.key}`} style={item.value ? { color: item.value } : {}}>{item.key}</span>)}
        </div>
        <div className="assessment-type-label">
          {reportType.label}
        </div>
        {reportType.iconPrefix && reportType.iconName && (
          <div className="assessment-type-icon">
            <FontAwesomeIcon icon={[reportType.iconPrefix, reportType.iconName]} className={reportType.iconColor ? '' : 'brand-icon'} style={reportType.iconColor ? { color: reportType.iconColor } : {}} />
          </div>
        )}
      </a>
      {reportType.videoUrl && <TooltipComponent text="Click to see a video" target="assessment-type-link" />}
    </>
  );
};

export default AssessmentType;
