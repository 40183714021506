import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipComponent from 'components/common/TooltipComponent';
import { AssessmentQuestionModel, AssessmentQuestion, AssessmentResponse } from 'models/assessment';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import {
  isNextPageAvailable as isNextPageAvailableSelector,
  currentPageAssessments as currentPageAssessmentsSelector,
} from 'state/selectors';
import { toast } from 'react-toastify';
import Api from 'api/agent';
import { history } from '../..';

interface Props {
  assessmentQuestion: AssessmentQuestionModel;
  optionClickCallback: () => void;
  pageChangedCallback?: () => void;
}

const AmountScale: React.FC<Props> = ({ assessmentQuestion, optionClickCallback, pageChangedCallback }) => {
  const [assessments, setAssessments] = useRecoilState(assessmentsAtom);
  const [assessmentPageNumber, setAssessmentPageNumber] = useRecoilState(assessmentPageNumberAtom);
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const isNextPageAvailable = useRecoilValue(isNextPageAvailableSelector);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);

  const setOption = (optionIdMost: number, optionIdLeast: number) => {
    optionClickCallback();

    const assessmentQuestionObject = new AssessmentQuestion(assessmentQuestion);
    if (optionIdMost > 0) {
      assessmentQuestionObject.selectedOptionIdMost = optionIdMost;
    }
    if (optionIdLeast > 0) {
      assessmentQuestionObject.selectedOptionIdLeast = optionIdLeast;
    }

    const assessmentsNew = currentPageAssessments.map((aq) => {
      if (aq.id === assessmentQuestion.id) {
        return assessmentQuestionObject;
      }

      return aq;
    });

    setAssessments(assessments.map((aq) => {
      if (aq.id === assessmentQuestion.id) {
        return assessmentQuestionObject;
      }

      return aq;
    }));

    if (assessmentQuestionObject.selectedOptionIdMost && assessmentQuestionObject.selectedOptionIdLeast) {
      Api.Assessments.save(new AssessmentResponse(assessmentQuestionObject))
        .then(() => {
          if (!isNextPageAvailable
            && assessmentsNew.every((a) => a.selectedOptionIdMost && a.selectedOptionIdLeast)
            && currentPageAssessments.some((a) => !a.selectedOptionIdMost || !a.selectedOptionIdLeast)) {
            history.push('/report');
          }
        })
        .catch((error) => {
          if (error.data?.message) {
            toast.error(error.data.message);
          }
        });
    }

    if (isNextPageAvailable
      && assessmentsNew.every((a) => a.selectedOptionIdMost && a.selectedOptionIdLeast)
      && currentPageAssessments.some((a) => !a.selectedOptionIdMost || !a.selectedOptionIdLeast)) {
      if (pageChangedCallback) {
        pageChangedCallback();
      }

      if (assessmentPageNumber === 0 || assessmentConfiguration.questionsPerPage === 1) {
        let timeout = 500;
        if (assessmentPageNumber === 0) {
          timeout = 1500;
        }

        setTimeout(() => {
          setAssessmentPageNumber(assessmentPageNumber + 1);
        }, timeout);
      } else {
        setAssessmentPageNumber(assessmentPageNumber + 1);
      }
    }
  };

  const resolveOptionStatus = (currentOptionId: number, selectedOptionId?: number) => {
    if (selectedOptionId === currentOptionId) {
      return 'active';
    }

    if (selectedOptionId) {
      return 'inactive';
    }

    return '';
  };

  return (
    <div className="amount-scale-component">
      <div className="amount-scale">
        <table>
          <tbody>
            <tr>
              <th className="amount-scale-question">{assessmentQuestion.title}</th>
              <th className="pr-3 pt-1 text-center">
                <div className="amount-scale-title most">
                  {assessmentQuestion.leftOptionLabel}
                </div>
              </th>
              <th className="pt-1 text-center">
                <div className="amount-scale-title least">
                  {assessmentQuestion.rightOptionLabel}
                </div>
              </th>
            </tr>
            {assessmentQuestion.options?.map((option) => (
              <tr key={option.id}>
                <td className="pr-5 pt-1 pb-2">
                  <div className="d-flex align-items-center">
                    <span className="amount-scale-option-title">{option.value}</span>
                    {option.tooltipText
                      && (
                        <>
                          <FontAwesomeIcon id={`questionIcon-${option.id}`} className="amount-scale-option-icon ml-2 mt-1" aria-hidden="true" icon={['fal', 'question-circle']} />
                          <TooltipComponent text={option.tooltipText} target={`questionIcon-${option.id}`} html bold />
                        </>
                      )}
                  </div>
                </td>
                <td className="pr-3 pt-1 pb-2">
                  <div
                    role="radio"
                    aria-checked={assessmentQuestion.selectedOptionIdMost === option.id}
                    className={`amount-option amount-option-most ${resolveOptionStatus(option.id, assessmentQuestion.selectedOptionIdMost)}`}
                    onClick={() => setOption(option.id, 0)}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'check']} />
                  </div>
                </td>
                <td className="pt-1 pb-2">
                  <div
                    role="radio"
                    aria-checked={assessmentQuestion.selectedOptionIdLeast === option.id}
                    className={`amount-option amount-option-least ${resolveOptionStatus(option.id, assessmentQuestion.selectedOptionIdLeast)}`}
                    onClick={() => setOption(0, option.id)}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'check']} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AmountScale.defaultProps = {
  pageChangedCallback: undefined,
};

export default AmountScale;
