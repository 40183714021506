import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPages as reportPagesAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
} from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReportPageItem } from 'models/report';
import {
  Alert, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import Button from 'components/common/Button';
import PromptModal from 'components/common/PromptModal';

interface Props {
  page: ReportPageItem;
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  builderMode?: boolean;
  preview?: boolean;
}

const ReportPage: React.FC<Props> = ({
  page, index, children, dragHandleProps, builderMode, preview,
}) => {
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);
  const [editModal, setEditModal] = useState(false);
  const [newPageName, setNewPageName] = useState(page?.name);
  const [forAssessmentType, setForAssessmentType] = useState<number | undefined>(page.forAssessmentType);
  const [isScrolled, setIsScrolled] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pageTypeChanged, setPageTypeChanged] = useState(false);

  const getScrollHeaderWidth = () => {
    const reportSection = document.getElementById('reportPageWrapper');
    if (reportSection) {
      return `${reportSection.clientWidth}px`;
    }
    return 'inherit';
  };

  const [scrollHeaderWidth, setScrollHeaderWidth] = useState(getScrollHeaderWidth());

  const toggleEditModal = () => {
    setEditModal(!editModal);
    setPageTypeChanged(false);
  };

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const getScrollHeaderTopOffset = () => {
    const navbar = document.getElementById('navbar');
    if (navbar) {
      return `${navbar.offsetHeight + 20}px`;
    }
    return 'unset';
  };

  const setForType = (value: string) => {
    const parsed = parseInt(value, 10);
    if (!Number.isNaN(parsed)) {
      setForAssessmentType(parsed);
    } else {
      setForAssessmentType(undefined);
    }
    setPageTypeChanged(true);
  };

  const deletePage = () => {
    const newState = [...reportPages];
    newState.splice(index, 1);
    setReportPages(newState);
  };

  const save = () => {
    const newState = reportPages.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name: newPageName,
          forAssessmentType,
          sections: item.sections.map((s) => ({ ...s, forAssessmentType })),
        };
      }
      return item;
    });
    setReportPages(newState);
    setEditModal(false);
    setPageTypeChanged(false);
  };

  const resolveReportType = (): string | undefined => {
    if (builderMode || preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else {
        forType = page.forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  const resolveDefaultForAssessmentType = () => {
    if (reportForType !== null && reportForType !== undefined) {
      return reportForType;
    }

    return page.forAssessmentType !== null ? page.forAssessmentType : undefined;
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setScrollHeaderWidth(getScrollHeaderWidth());
    });
    if (!builderMode) {
      const reportSection = document.getElementById('reportPageWrapper');
      if (reportSection) {
        resizeObserver.observe(reportSection);
      }
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!builderMode) {
      window.onscroll = () => {
        if (window.pageYOffset > 140) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
    }
  }, []);

  return (
    <Container>
      <div id={`reportPage-${index}`} className="report-page">
        <div className="report-page-header-scroll" style={{ display: (isScrolled ? 'block' : 'none'), top: getScrollHeaderTopOffset(), width: scrollHeaderWidth }}>
          <div className="report-page-header-scroll-ornament" />
          <div className="report-page-header-scroll-heading">
            <span className="report-page-header-scroll-number">{(index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</span>
            <span className="report-page-header-scroll-text">
              {page.name}
              {resolveReportType()}
            </span>
          </div>
        </div>
        <div className="report-page-header" style={{ opacity: (isScrolled ? 0 : 1) }}>
          <div className="report-page-header-heading">
            <span className="report-page-header-number">{(index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</span>
            <span className="report-page-header-text">
              {page.name}
              {resolveReportType()}
            </span>
          </div>
          {builderMode && (
            <>
              <span className="text-nowrap">
                <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2" style={{ cursor: 'pointer' }} onClick={() => toggleEditModal()} />
                <FontAwesomeIcon
                  icon={['fas', 'trash-alt']}
                  className="mr-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleDeleteModal()}
                />
                <span {...dragHandleProps}><FontAwesomeIcon icon={['fas', 'arrows-alt']} /></span>
              </span>
              <PromptModal headerText="Delete page" confirmAction={() => deletePage()} isOpen={deleteModal} toggle={toggleDeleteModal}>
                Are you sure you want to delete this page?
              </PromptModal>
            </>
          )}
        </div>
        <div id="reportPageWrapper" className="report-page-wrapper">
          <div className="report-page-content">
            {children}
          </div>
        </div>
      </div>
      {builderMode && (
        <Modal backdrop="static" size="lg" isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>
            Edit
            {' '}
            {page?.name}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Label for="pageName" sm={2}>Title</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="pageName"
                  id="pageName"
                  placeholder="Enter page title"
                  value={newPageName}
                  onChange={(e) => setNewPageName(e.target.value)}
                />
              </Col>
            </Row>
            {reportAvailableTypes?.length > 0 && (
              <Row className="mt-2">
                <Label for="forPageAssessmentType" sm={2}>For Type</Label>
                <Col sm={10}>
                  <Input
                    type="select"
                    name="forPageAssessmentType"
                    id="forPageAssessmentType"
                    defaultValue={resolveDefaultForAssessmentType()}
                    onChange={(e) => setForType(e.target.value)}
                    disabled={reportForType !== null && reportForType !== undefined}
                  >
                    {reportAvailableTypes.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                      >
                        {item.key}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            )}
            {pageTypeChanged && (
              <Row className="mt-2 d-flex justify-content-center">
                <Alert color="warning" className="mb-0">
                  If you update this page&apos;s
                  <strong> &quot;For Type&quot; </strong>
                  field, it will convert all the page sections to the new Type you have chosen.
                </Alert>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => deletePage()}>Delete Page</Button>
            {' '}
            <Button color="primary" onClick={() => save()}>Save Section</Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

ReportPage.defaultProps = {
  dragHandleProps: undefined,
  builderMode: false,
  preview: false,
};

export default ReportPage;
