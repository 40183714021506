import React from 'react';
import { useRecoilState } from 'recoil';
import { reportPages as reportPagesAtom } from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import ReportTableOfContentsPage from './ReportTableOfContentsPage';

interface Props {
  currentPageIndex?: number;
  allowCollapse?: boolean;
  disableNavigation?: boolean;
  preview?: boolean;
  buttonCallback?: () => void;
}

const ReportTableOfContents: React.FC<Props> = ({
  currentPageIndex, allowCollapse, disableNavigation, preview, buttonCallback,
}) => {
  const [reportPages] = useRecoilState(reportPagesAtom);

  return (
    <div className="report-toc">
      <div className="report-toc-header">
        <div>
          <FontAwesomeIcon icon={['fas', 'stream']} className="report-toc-header-icon" />
          <span className="report-toc-header-text">Table of Contents</span>
        </div>
        <Button
          outline
          color="secondary"
          size="sm"
          className="report-toc-header-button"
          onClick={buttonCallback}
        >
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </Button>
      </div>
      {reportPages && reportPages.map((page, pageIndex) => (
        <ReportTableOfContentsPage
          key={page.id}
          page={page}
          pageIndex={pageIndex}
          pageIsOpened={currentPageIndex === undefined || currentPageIndex === pageIndex}
          allowCollapse={allowCollapse}
          disableNavigation={disableNavigation}
          preview={preview}
        />
      ))}
    </div>
  );
};

ReportTableOfContents.defaultProps = {
  currentPageIndex: undefined,
  buttonCallback: undefined,
  allowCollapse: true,
  disableNavigation: false,
  preview: false,
};

export default ReportTableOfContents;
