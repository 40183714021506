import React, { useState } from 'react';
import {
  NavbarBrand, Media, Collapse,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import {
  assessmentConfiguration as assessmentConfigurationAtom,
  reportType as reportTypeAtom,
} from 'state/atoms';
import Button from 'components/common/Button';
import AssessmentType from 'components/assessment-result/AssessmentType';
import AssessmentGraph from 'components/assessment-result/AssessmentGraph';
import ReportTableOfContentsMobile from 'components/report-toc/ReportTableOfContentsMobile';
import NavbarBase from '../layout/NavbarBase';

const ReportNavMenuMobile: React.FC = () => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const reportType = useRecoilValue(reportTypeAtom);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [chartRendered, setChartRendered] = useState(false);

  const toggleCollapse = () => {
    setIsMenuOpen(false);
    setIsTypeOpen(!isTypeOpen);
    setChartRendered(true);
  };

  const onMenuButtonClick = () => {
    setIsTypeOpen(false);
    setIsMenuOpen(!isMenuOpen);
  };

  const backToProfile = () => {
    window.location.href = assessmentConfiguration.returnUrl;
  };

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  return (
    <>
      <NavbarBase id="navMenuMobile" height={getNavbarHeight()}>
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div className="d-flex flex-nowrap align-items-center">
            {assessmentConfiguration.logoUrl && (
              <NavbarBrand tag={Link} to="/">
                <Media src={assessmentConfiguration.logoUrl} alt="logo" />
              </NavbarBrand>
            )}
          </div>
          <Button outline color="secondary" onClick={() => toggleCollapse()} className="navmenu-mobile-type-button">
            <span className="mr-2">
              <FontAwesomeIcon icon={[reportType.iconPrefix ?? 'fas', reportType.iconName ?? 'award']} className="mr-2" />
              <span className="text-uppercase">
                {reportType.name
                  ? reportType.name?.map((item) => <span key={`assessment-type-${item.key}`}>{item.key}</span>)
                  : reportType.label}
              </span>
            </span>
            <FontAwesomeIcon icon={['fas', isTypeOpen ? 'caret-up' : 'caret-down']} />
          </Button>
          <Button outline color="secondary" className="navmenu-mobile-menu-button">
            <FontAwesomeIcon icon={['fas', isMenuOpen ? 'times' : 'bars']} onClick={() => onMenuButtonClick()} />
          </Button>
        </div>
        <Collapse isOpen={isTypeOpen} className={`w-100 ${isMenuOpen ? 'd-none' : ''}`}>
          <div className="spacer" />
          <div className="collapse-container">
            <AssessmentType />
            <AssessmentGraph location="nav-mobile" renderChart={chartRendered} />
          </div>
        </Collapse>
        <Collapse isOpen={isMenuOpen} className={`w-100 ${isTypeOpen ? 'd-none' : ''}`}>
          <div className="spacer">
            <Button outline color="secondary" className="text-nowrap" onClick={() => backToProfile()}>
              Back to Profile
              <FontAwesomeIcon icon={['fas', 'sign-out']} className="ml-2" />
            </Button>
          </div>
          <div className="collapse-container">
            <ReportTableOfContentsMobile sectionClickCallback={() => onMenuButtonClick()} />
          </div>
        </Collapse>
      </NavbarBase>
    </>
  );
};

export default ReportNavMenuMobile;
