import React from 'react';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import Button from 'components/common/Button';

interface Props {
  headerText: string;
  confirmAction: () => void;
  isOpen: boolean;
  toggle: () => void;
}

const PromptModal: React.FC<Props> = ({
  headerText, confirmAction, isOpen, toggle, children,
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Cancel</Button>
      {' '}
      <Button color="primary" onClick={() => confirmAction()}>Confirm</Button>
    </ModalFooter>
  </Modal>
);

export default PromptModal;
