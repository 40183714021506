import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssessmentQuestionModel, AssessmentQuestion, AssessmentResponse } from 'models/assessment';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import {
  isNextPageAvailable as isNextPageAvailableSelector,
  currentPageAssessments as currentPageAssessmentsSelector,
} from 'state/selectors';
import { toast } from 'react-toastify';
import Api from 'api/agent';
import { history } from '../..';

interface Props {
  assessmentQuestion: AssessmentQuestionModel;
  optionClickCallback: () => void;
  pageChangedCallback?: () => void;
}

const LikertScale: React.FC<Props> = ({ assessmentQuestion, optionClickCallback, pageChangedCallback }) => {
  const [assessments, setAssessments] = useRecoilState(assessmentsAtom);
  const [assessmentPageNumber, setAssessmentPageNumber] = useRecoilState(assessmentPageNumberAtom);
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const isNextPageAvailable = useRecoilValue(isNextPageAvailableSelector);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);

  const setOption = (option: number) => {
    optionClickCallback();

    if (!assessmentQuestion?.selectedOptionId && !assessmentQuestion?.active) {
      return;
    }

    const assessmentQuestionObject = new AssessmentQuestion(assessmentQuestion);
    assessmentQuestionObject.selectedOptionId = option;

    const assessmentsNew = currentPageAssessments.map((aq) => {
      if (aq.id === assessmentQuestion.id) {
        const questionObject = new AssessmentQuestion(assessmentQuestion);
        questionObject.selectedOptionId = option;
        return questionObject;
      }

      return aq;
    });

    let setNextAsActive = false;
    let nextQuestionId: number | undefined;
    setAssessments(assessments.map((aq) => {
      let questionObject: AssessmentQuestion;
      if (setNextAsActive && !aq.selectedOptionId) {
        setNextAsActive = false;
        questionObject = new AssessmentQuestion(aq);
        questionObject.active = true;
        nextQuestionId = questionObject.id;
        return questionObject;
      }

      if (aq.id === assessmentQuestion.id) {
        questionObject = new AssessmentQuestion(assessmentQuestion);
        questionObject.selectedOptionId = option;
        questionObject.active = false;
        setNextAsActive = true;
        return questionObject;
      }

      return aq;
    }));

    if (assessmentQuestion.active && nextQuestionId) {
      const nextQuestion = document.getElementById(`assessment-${nextQuestionId}`);
      nextQuestion?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }

    Api.Assessments.save(new AssessmentResponse(assessmentQuestionObject))
      .then(() => {
        if (!isNextPageAvailable
          && assessmentsNew.every((a) => a.selectedOptionId)
          && currentPageAssessments.some((a) => !a.selectedOptionId)) {
          history.push('/report');
        }
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        }
      });

    if (isNextPageAvailable
      && assessmentsNew.every((a) => a.selectedOptionId)
      && currentPageAssessments.some((a) => !a.selectedOptionId)) {
      if (pageChangedCallback) {
        pageChangedCallback();
      }

      if (assessmentPageNumber === 0 || assessmentConfiguration.questionsPerPage === 1) {
        let timeout = 500;
        if (assessmentPageNumber === 0) {
          timeout = 1500;
        }

        setTimeout(() => {
          setAssessmentPageNumber(assessmentPageNumber + 1);
        }, timeout);
      } else {
        setAssessmentPageNumber(assessmentPageNumber + 1);
      }
    }
  };

  return (
    <div className="likert-scale-component">
      <div className="likert-scale">
        <span className="row-item scale-labels label-left">{assessmentQuestion.leftOptionLabel}</span>
        {assessmentQuestion.options?.map((option, index) => (
          <div
            key={option.id}
            role="radio"
            aria-checked={assessmentQuestion.selectedOptionId === option.id}
            className={`row-item likert-option likert-option-${index + 1}${assessmentQuestion.selectedOptionId === option.id ? ' active' : ''}`}
            onClick={() => setOption(option.id)}
          >
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'check']} />
          </div>
        ))}
        <span className="row-item scale-labels label-right">{assessmentQuestion.rightOptionLabel}</span>
      </div>
      <div className="scale-labels-mobile">
        <span className="label-left">{assessmentQuestion.leftOptionLabel}</span>
        <span className="label-right">{assessmentQuestion.rightOptionLabel}</span>
      </div>
    </div>
  );
};

LikertScale.defaultProps = {
  pageChangedCallback: undefined,
};

export default LikertScale;
