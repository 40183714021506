import React from 'react';
import { Container } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { assessmentPageNumber as assessmentPageNumberAtom } from 'state/atoms';
import { currentPageAssessments as currentPageAssessmentsSelector } from 'state/selectors';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Assessment from './Assessment';
import PracticeQuestion from './PracticeQuestion';

interface Props {
  pageDirection: string;
  optionClickCallback: () => void;
}

const SingularAssessment: React.FC<Props> = ({ pageDirection, optionClickCallback }) => {
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const assessmentPageNumber = useRecoilValue(assessmentPageNumberAtom);

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');
    return footer?.offsetHeight;
  };

  const resolveScrollDIrection = () => {
    if (pageDirection === 'prev') {
      return 'left';
    }

    return 'right';
  };

  const renderQuestion = () => {
    if (assessmentPageNumber === 0) {
      return (
        <div className="practice">
          <div style={{ paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
            <PracticeQuestion optionClickCallback={optionClickCallback} />
          </div>
        </div>
      );
    }

    return (
      <div className="singular-content">
        <div style={{ paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
          {currentPageAssessments.map((assessmentQuestion) => (
            <Assessment
              key={assessmentQuestion.id}
              assessmentQuestion={assessmentQuestion}
              optionClickCallback={optionClickCallback}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <TransitionGroup className="slide-group-singular">
      <CSSTransition
        classNames={resolveScrollDIrection()}
        timeout={{ enter: 500, exit: 500 }}
        key={`${assessmentPageNumber}`}
      >
        <div className="assessments-content">
          <Container>
            {renderQuestion()}
          </Container>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default SingularAssessment;
