import { AssessmentQuestionModel } from 'models/assessment';
import { RecoilValueReadOnly, selector } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
  assessmentsPageModel as assessmentsPageModelAtom,
} from './atoms';

export const assessmentsCompleted = selector({
  key: 'assessmentsCompleted',
  get: ({ get }) => {
    const assessments = get(assessmentsAtom);
    const pageModel = get(assessmentsPageModelAtom);
    const count = pageModel?.totalRecords ?? 0;

    if (count > 0) {
      const completed = assessments.reduce((total, a) => ((a.selectedOptionId || (a.selectedOptionIdMost && a.selectedOptionIdLeast)) ? total + 1 : total), 0);
      const completedValue = (100 / count) * completed;
      let percentage: number;
      if (completedValue >= 99) {
        percentage = Math.floor(completedValue);
      } else {
        percentage = Math.round(completedValue);
      }

      if (percentage > 100) {
        return 100;
      }

      return percentage;
    }

    return 0;
  },
});

export const currentPageAssessments: RecoilValueReadOnly<AssessmentQuestionModel[]> = selector({
  key: 'currentPageAssessments',
  get: ({ get }) => {
    const assessments = get(assessmentsAtom);
    const pageNumber = get(assessmentPageNumberAtom);
    const assessmentConfiguration = get(assessmentConfigurationAtom);

    if (!assessments.length) {
      return [];
    }

    if (pageNumber === 0) {
      return assessments.slice(0, 1);
    }

    return assessments.slice(1).slice((pageNumber - 1) * assessmentConfiguration.questionsPerPage, pageNumber * assessmentConfiguration.questionsPerPage);
  },
});

export const isNextPageAvailable = selector({
  key: 'isNextPageAvailable',
  get: ({ get }) => {
    const pageNumber = get(assessmentPageNumberAtom);
    const pageModel = get(assessmentsPageModelAtom);
    const assessmentConfiguration = get(assessmentConfigurationAtom);

    if (!pageModel?.totalRecords) {
      return false;
    }

    return (pageNumber * assessmentConfiguration.questionsPerPage) < pageModel.totalRecords - 1;
  },
});
