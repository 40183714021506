import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPageNumber as reportPageNumberAtom,
  showReportTocLite as showReportTocLiteAtom,
} from 'state/atoms';
import ReportTableOfContents from 'components/report-toc/ReportTableOfContents';
import ReportTableOfContentsLite from 'components/report-toc/ReportTableOfContentsLite';

interface Props {
  preview?: boolean;
}

const ReportTocContainer: React.FC<Props> = ({ preview }) => {
  const reportPageNumber = useRecoilValue(reportPageNumberAtom);
  const [showReportTocLite, setShowReportTocLite] = useRecoilState(showReportTocLiteAtom);

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');
    return footer?.offsetHeight;
  };

  return (
    <div className="report-toc-container" style={{ width: showReportTocLite ? 50 : 240, paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
      <div className={`${(showReportTocLite ? '' : ' d-none')}`}>
        <ReportTableOfContentsLite
          key={`ReportTableOfContentsLite${reportPageNumber}`}
          currentPageIndex={reportPageNumber}
          preview={preview}
          buttonCallback={() => setShowReportTocLite(false)}
        />
      </div>
      <div className={`${(showReportTocLite ? ' d-none' : '')}`}>
        <ReportTableOfContents
          key={`ReportTableOfContents${reportPageNumber}`}
          currentPageIndex={reportPageNumber}
          preview={preview}
          buttonCallback={() => setShowReportTocLite(true)}
        />
      </div>
    </div>
  );
};

ReportTocContainer.defaultProps = {
  preview: false,
};

export default ReportTocContainer;
