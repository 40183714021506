import React from 'react';
import { NavbarBrand, NavbarText, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { assessmentConfiguration as assessmentConfigurationAtom } from 'state/atoms';
import Button from 'components/common/Button';
import NavbarBase from './NavbarBase';

interface Props {
  buttonText?: string,
  buttonCallback?: () => void;
}

const NavMenu: React.FC<Props> = ({ buttonText, buttonCallback }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);

  const buttonClick = () => {
    if (buttonCallback) {
      buttonCallback();
    } else {
      window.location.href = assessmentConfiguration.returnUrl;
    }
  };

  return (
    <NavbarBase id="navbar">
      <div className="d-flex flex-nowrap align-items-center">
        {assessmentConfiguration.logoUrl && (
          <NavbarBrand>
            <Media src={assessmentConfiguration.logoUrl} alt="logo" />
          </NavbarBrand>
        )}
        <NavbarText className="text-dark">
          <span className="pr-2">|</span>
          {assessmentConfiguration.name}
        </NavbarText>
      </div>
      <Button color="secondary" className="text-nowrap" onClick={() => buttonClick()}>
        {buttonText}
        <FontAwesomeIcon icon={['fas', 'sign-out']} className="ml-2" />
      </Button>
    </NavbarBase>
  );
};

NavMenu.defaultProps = {
  buttonText: 'Exit',
  buttonCallback: undefined,
};

export default NavMenu;
