import Axios, { AxiosResponse } from 'axios';
import { AssessmentQuestionModel, AssessmentResponseModel } from 'models/assessment';
import { AssessmentCompletedCount } from 'models/assessmentCompletedCount';
import { AssessmentConfigurationModel } from 'models/assessmentConfiguration';
import { PagedListResult } from 'models/pagedListResult';
import { Photo } from 'models/photo';
import { ReportData, ReportListData, ReportPageItem } from 'models/report';
import { toast } from 'react-toastify';
import { localStorage, MARKETING_PAGE } from 'utils/constants';
import { history } from '..';

Axios.defaults.baseURL = '/api';

Axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(localStorage.JWT_TOKEN);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(undefined, (response) => {
  if (!response.response) {
    toast.error('An unknown error occurred. Please contact the administrator!');
  }

  const { status } = response.response;
  if (status === 403) {
    history.push('/403');
  } else if (status === 404) {
    history.push('/404');
  } else if (status === 401) {
    const webUrl = window.localStorage.getItem(localStorage.WEB_URL);
    if (webUrl) {
      window.location.href = webUrl;
    } else {
      window.location.href = MARKETING_PAGE;
    }
  } else if (status === 500) {
    toast.error('A server error occurred. Please contact the administrator!');
  }

  throw response.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => Axios.get(url).then(responseBody),
  post: (url: string, body?: unknown) => Axios.post(url, body).then(responseBody),
  put: (url: string, body?: unknown) => Axios.put(url, body).then(responseBody),
  delete: (url: string) => Axios.delete(url).then(responseBody),
  postForm: async (url: string, file: Blob) => {
    const formData = new FormData();
    formData.append('File', file);
    const response = await Axios.post(url, formData, {
      headers: { 'Content-type': 'multipart/form-data' },
    });
    return responseBody(response);
  },
};

const Api = {
  Assessments: {
    get: (page: number, pageSize: number): Promise<PagedListResult<AssessmentQuestionModel>> => requests.get(`assessments?page=${page}&pageSize=${pageSize}`),
    getAll: (): Promise<PagedListResult<AssessmentQuestionModel>> => requests.get('assessments/all'),
    getConfig: (): Promise<AssessmentConfigurationModel> => requests.get('assessments/configuration'),
    getCompletedCount: (): Promise<AssessmentCompletedCount> => requests.get('assessments/completed-count'),
    getReportConfigs: (): Promise<ReportListData> => requests.get('assessments/report-config'),
    getReportConfig: (reportId?: string): Promise<ReportData> => requests.get(`assessments/report-config/${reportId}`),
    saveAll: (assessments: AssessmentResponseModel[]): Promise<void> => requests.post('assessments', assessments),
    save: (assessment: AssessmentResponseModel): Promise<void> => requests.post(`assessments/${assessment.questionId}`, assessment),
    complete: (): Promise<ReportData> => requests.post('assessments/complete', {}),
    saveReportConfig: (reportId: string, pages: ReportPageItem[]): Promise<ReportData> => requests.post(`assessments/report-config/${reportId}`, { pages }),
    saveReportConfigs: (reports: ReportData[]): Promise<ReportListData> => requests.post('assessments/report-config', { reports }),
    deleteReportConfig: (reportId?: number): Promise<ReportData> => requests.delete(`assessments/report-config/${reportId}`),
    clear: (): Promise<void> => requests.put('assessments/clear'),
  },
  Image: {
    upload: (photo: Blob): Promise<Photo> => requests.postForm('/photos', photo),
  },
};

export default Api;
