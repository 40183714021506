import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPages as reportPagesAtom,
  reportType as reportTypeAtom,
} from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import ReportTableOfContentsLitePage from './ReportTableOfContentsLitePage';

interface Props {
  currentPageIndex?: number;
  disableNavigation?: boolean;
  preview?: boolean;
  buttonCallback?: () => void;
}

const ReportTableOfContentsLite: React.FC<Props> = ({
  currentPageIndex, disableNavigation, preview, buttonCallback,
}) => {
  const [reportPages] = useRecoilState(reportPagesAtom);
  const reportType = useRecoilValue(reportTypeAtom);

  return (
    <div className="report-toc-lite">
      <div className="report-toc-lite-header">
        <Button
          outline
          color="secondary"
          size="sm"
          className="report-toc-lite-header-button"
          onClick={buttonCallback}
        >
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </Button>
        <FontAwesomeIcon
          icon={['fas', 'stream']}
          className={`report-toc-lite-header-icon text-white ${reportType.iconColor ? '' : 'brand-primary-bg'}`}
          style={reportType.iconColor ? { backgroundColor: reportType.iconColor } : {}}
        />
      </div>
      {reportPages && reportPages.map((page, pageIndex) => (
        <ReportTableOfContentsLitePage
          key={page.id}
          page={page}
          pageIndex={pageIndex}
          pageIsOpened={currentPageIndex === undefined || currentPageIndex === pageIndex}
          disableNavigation={disableNavigation}
          preview={preview}
        />
      ))}
    </div>
  );
};

ReportTableOfContentsLite.defaultProps = {
  currentPageIndex: undefined,
  disableNavigation: false,
  buttonCallback: undefined,
  preview: false,
};

export default ReportTableOfContentsLite;
