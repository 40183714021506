import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  assessmentConfiguration as assessmentConfigurationAtom,
  reportPages as reportPagesAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
  reportBuilderTools as reportBuilderToolsAtom,
} from 'state/atoms';
import Api from 'api/agent';
import NavMenu from 'components/layout/NavMenu';
import Loader from 'components/common/Loader';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ReportItemTypes } from 'models/reportItemTypes';
import { ReportSectionItem, ReportItemType, ReportPageItem } from 'models/report';
import { ReportBuilderToolType } from 'models/reportBuilderTool';
import { toast } from 'react-toastify';
import ReportBuilderToolbarContainer from './ReportBuilderToolbarContainer';
import ReportBuilderContainer from './ReportBuilderContainer';
import ReportBuilderTocContainer from './ReportBuilderTocContainer';

interface RouteParams {
  reportId?: string;
}

const ReportBuilder: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const setReportAvailableTypes = useSetRecoilState(reportAvailableTypesAtom);
  const setReportForType = useSetRecoilState(reportForTypeAtom);
  const reportBuilderTools = useRecoilValue(reportBuilderToolsAtom);
  const [loading, setLoading] = useState(true);
  const [pageCounter, setPageCounter] = useState(-1);
  const [sectionCounter, setSectionCounter] = useState(-1);
  const [offset, setOffset] = useState(0);

  const backToProfile = () => {
    window.location.href = assessmentConfiguration.returnUrl;
  };

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const resolveSectionType = (toolType: ReportBuilderToolType) => {
    switch (toolType) {
      case ReportBuilderToolType.TextEditor:
        return ReportItemType.TextEditor;
      case ReportBuilderToolType.AssessmentResult:
        return ReportItemType.AssessmentResult;
      case ReportBuilderToolType.DCharacteristics:
        return ReportItemType.DCharacteristics;
      case ReportBuilderToolType.ICharacteristics:
        return ReportItemType.ICharacteristics;
      case ReportBuilderToolType.SCharacteristics:
        return ReportItemType.SCharacteristics;
      case ReportBuilderToolType.CCharacteristics:
        return ReportItemType.CCharacteristics;
      default:
        return ReportItemType.TextEditor;
    }
  };

  const addPage = (destIndex: number) => {
    const newItem = [...reportPages];
    newItem.splice(destIndex, 0, {
      id: pageCounter,
      name: ReportBuilderToolType[ReportBuilderToolType.Page],
      sections: [],
    });
    setPageCounter(pageCounter - 1);
    setReportPages(newItem);
  };

  const addSection = (destParentId: number, destIndex: number, toolType: number) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const itemSubItemMap = reportPages.reduce((acc: any, item) => {
      acc[item.id] = item.sections;
      return acc;
    }, {});

    const destSubItems = itemSubItemMap[destParentId];
    const newDestSubItems = [...destSubItems];
    newDestSubItems.splice(destIndex, 0, {
      id: sectionCounter,
      name: reportBuilderTools.find((tool) => tool.type === toolType)?.name ?? ReportBuilderToolType[toolType],
      pageId: destParentId,
      type: resolveSectionType(toolType),
    });
    setSectionCounter(sectionCounter - 1);
    setReportPages(reportPages.map((item) => {
      if (item.id === destParentId) {
        return { ...item, sections: newDestSubItems };
      }
      return item;
    }));
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceIndex = source.index;
    const destIndex = destination.index;
    if (result.type === ReportItemTypes.PAGE) {
      if (source.droppableId.includes('tool')) {
        addPage(destIndex);
        return;
      }

      const items = reorder<ReportPageItem>(reportPages, sourceIndex, destIndex);
      setReportPages(items);
    } else if (result.type === ReportItemTypes.SECTION) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const itemSubItemMap = reportPages.reduce((acc: any, item) => {
        acc[item.id] = item.sections;
        return acc;
      }, {});

      if (source.droppableId.includes('tool')) {
        const toolType = parseInt(source.droppableId.split('#')[1], 10);
        const destParentId = parseInt(destination.droppableId, 10);
        addSection(destParentId, destIndex, toolType);
        return;
      }

      const sourceParentId = parseInt(source.droppableId, 10);
      const destParentId = parseInt(destination.droppableId, 10);

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder<ReportSectionItem>(
          sourceSubItems,
          sourceIndex,
          destIndex,
        );
        setReportPages(reportPages.map((item) => {
          if (item.id === sourceParentId) {
            return { ...item, sections: reorderedSubItems };
          }
          return item;
        }));
      } else {
        const newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        const newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        setReportPages(reportPages.map((item) => {
          if (item.id === sourceParentId) {
            return { ...item, sections: newSourceSubItems };
          } if (item.id === destParentId) {
            return { ...item, sections: newDestSubItems };
          }
          return item;
        }));
      }
    }
  };

  useEffect(() => {
    Api.Assessments.getReportConfig(match.params.reportId)
      .then((data) => {
        if (data?.pages) {
          setReportPages(data.pages);
        }
        if (data?.availableTypes) {
          setReportAvailableTypes(data.availableTypes);
        }
        setReportForType(data?.forAssessmentType);
        setLoading(false);
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        }
      });
  }, [setReportPages, setReportAvailableTypes]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <NavMenu buttonText="Back to Profile" buttonCallback={backToProfile} />
      {loading
        ? <Loader />
        : (
          <div className="report-builder" style={{ paddingTop: getNavbarHeight() }}>
            <ReportBuilderTocContainer />
            <ReportBuilderContainer reportId={match.params.reportId ?? ''} addPage={addPage} addSection={addSection} />
            <div style={{ marginTop: offset }}>
              <ReportBuilderToolbarContainer />
            </div>
          </div>
        )}
    </DragDropContext>
  );
};

export default ReportBuilder;
