import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPages as reportPagesAtom,
  reportPageNumber as reportPageNumberAtom,
  showReportTocLite as showReportTocLiteAtom,
  showReportTypeLite as showReportTypeLiteAtom,
} from 'state/atoms';
import ReportPage from './ReportPage';
import ReportSection from './ReportSection';

interface Props {
  preview?: boolean;
}

const ReportContainer: React.FC<Props> = ({ preview }) => {
  const [reportPages] = useRecoilState(reportPagesAtom);
  const reportPageNumber = useRecoilValue(reportPageNumberAtom);
  const showReportTocLite = useRecoilValue(showReportTocLiteAtom);
  const showReportTypeLite = useRecoilValue(showReportTypeLiteAtom);

  return (
    <div
      id="reportContent"
      className="report-content"
      style={{ marginLeft: showReportTocLite ? 50 : 240, marginRight: showReportTypeLite ? 50 : 240 }}
    >
      <div className="report-content-header">
        <div className="report-content-header-item" />
      </div>
      {reportPages[reportPageNumber]
        && (
          <ReportPage
            key={reportPages[reportPageNumber].id}
            page={reportPages[reportPageNumber]}
            index={reportPageNumber}
            preview={preview}
          >
            {reportPages[reportPageNumber].sections.map((section, sectionIndex) => (
              <ReportSection
                page={reportPages[reportPageNumber]}
                key={section.id}
                section={section}
                pageIndex={reportPageNumber}
                sectionIndex={sectionIndex}
                preview={preview}
              />
            ))}
          </ReportPage>
        )}
    </div>
  );
};

ReportContainer.defaultProps = {
  preview: false,
};

export default ReportContainer;
