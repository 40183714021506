import React from 'react';
import { Button as BootstrapButton, ButtonProps } from 'reactstrap';

const Button: React.FC<ButtonProps> = (props) => {
  const { className } = props;

  const resolveClass = () => {
    const baseClassName = 'custom-btn';

    if (className) {
      return `${baseClassName} ${className}`;
    }

    return baseClassName;
  };

  return (
    <BootstrapButton {...props} className={resolveClass()} />
  );
};

export default Button;
