import React, { useState } from 'react';
import { Tooltip, TooltipProps } from 'reactstrap';
import DOMPurify from 'dompurify';

interface Props extends TooltipProps {
  text?: string;
  html?: boolean;
  bold?: boolean;
  trigger?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

const TooltipComponent: React.FC<Props> = (props) => {
  const {
    text,
    html,
    bold,
    textAlign,
    ...other
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {text
        && (
          <Tooltip
            {...other}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
            style={{ fontWeight: bold ? 'bold' : 'normal', textAlign }}
          >
            {
              html
                ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} /> // eslint-disable-line react/no-danger
                : text
            }
          </Tooltip>
        )}
    </>
  );
};

TooltipComponent.defaultProps = {
  text: undefined,
  html: false,
  bold: false,
  trigger: 'hover',
  textAlign: 'center',
};

export default TooltipComponent;
