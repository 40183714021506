import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReportPageItem, ReportSectionItem, ReportItemType } from 'models/report';
import TextEditor from 'components/report-sections/TextEditor';
import DefaultSection from 'components/report-sections/DefaultSection';
import AssessmentResult from 'components/report-sections/AssessmentResult';
import DiscCharacteristics from 'components/report-sections/disc/DiscCharacteristics';
import GenerosityOverview from 'components/report-sections/disc/GenerosityOverview';
import GenerositySection from 'components/report-sections/disc/GenerositySection';

interface Props {
  page: ReportPageItem;
  section: ReportSectionItem;
  pageIndex: number;
  sectionIndex: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  builderMode?: boolean;
  preview?: boolean;
}

const ReportSection: React.FC<Props> = ({
  page, section, pageIndex, sectionIndex, dragHandleProps, builderMode, preview,
}) => {
  const renderSection = () => {
    switch (section?.type) {
      case ReportItemType.TextEditor:
        return (
          <TextEditor
            page={page}
            section={section}
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            dragHandleProps={dragHandleProps}
            builderMode={builderMode}
            preview={preview}
          />
        );
      case ReportItemType.AssessmentResult:
        return (
          <AssessmentResult
            page={page}
            section={section}
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            dragHandleProps={dragHandleProps}
            builderMode={builderMode}
            preview={preview}
          />
        );
      case ReportItemType.DCharacteristics:
      case ReportItemType.ICharacteristics:
      case ReportItemType.SCharacteristics:
      case ReportItemType.CCharacteristics:
        return (
          <DiscCharacteristics
            page={page}
            section={section}
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            dragHandleProps={dragHandleProps}
            builderMode={builderMode}
            preview={preview}
          />
        );
      case ReportItemType.GenerosityOverview:
        return (
          <GenerosityOverview
            page={page}
            section={section}
            sectionIndex={sectionIndex}
            builderMode={builderMode}
            preview={preview}
          />
        );
      case ReportItemType.GenerosityGivingStrengths:
      case ReportItemType.GenerosityGivingCautions:
      case ReportItemType.GenerosityGivingExamples:
        return (
          <GenerositySection
            page={page}
            section={section}
            sectionIndex={sectionIndex}
            builderMode={builderMode}
            preview={preview}
          />
        );
      default:
        return (
          <DefaultSection
            page={page}
            section={section}
            pageIndex={pageIndex}
            sectionIndex={sectionIndex}
            dragHandleProps={dragHandleProps}
            builderMode={builderMode}
            preview={preview}
          />
        );
    }
  };

  return renderSection();
};

ReportSection.defaultProps = {
  dragHandleProps: undefined,
  builderMode: false,
  preview: false,
};

export default ReportSection;
