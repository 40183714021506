import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPages as reportPagesAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReportPageItem, ReportSectionItem } from 'models/report';
import {
  Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import Button from 'components/common/Button';
import PromptModal from 'components/common/PromptModal';
import DOMPurify from 'dompurify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'impact-app-ckeditor5-build';
import TextEditorUploadAdapter from '../../utils/ImageUploadAdapter';

interface Props {
  page: ReportPageItem;
  section: ReportSectionItem;
  pageIndex: number;
  sectionIndex: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  builderMode?: boolean;
  preview?: boolean;
}

const TextEditor: React.FC<Props> = ({
  page, section, pageIndex, sectionIndex, dragHandleProps, builderMode, preview,
}) => {
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const [editModal, setEditModal] = useState(false);
  const [newSectionName, setNewSectionName] = useState(section?.name);
  const [newSectionContent, setNewSectionContent] = useState(section?.content);
  const [forAssessmentType, setForAssessmentType] = useState<number | undefined>(section.forAssessmentType);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleEditModal = () => setEditModal(!editModal);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const setForType = (value: string) => {
    const parsed = parseInt(value, 10);
    if (!Number.isNaN(parsed)) {
      setForAssessmentType(parsed);
    } else {
      setForAssessmentType(undefined);
    }
  };

  const deleteSection = () => {
    const newState = [...reportPages[pageIndex].sections];
    newState.splice(sectionIndex, 1);
    setReportPages(reportPages.map((item, i) => {
      if (i === pageIndex) {
        return { ...item, sections: newState };
      }
      return item;
    }));
  };

  const save = () => {
    const newSectionsState = reportPages[pageIndex].sections
      .map((item, i) => {
        if (i === sectionIndex) {
          return {
            ...item,
            name: newSectionName,
            forAssessmentType,
            content: newSectionContent,
          };
        }
        return item;
      });
    const newPageState = reportPages.map((item, i) => {
      if (i === pageIndex) {
        return { ...item, sections: newSectionsState };
      }
      return item;
    });
    setReportPages(newPageState);
    setEditModal(false);
  };

  const resolveReportType = (): string | undefined => {
    if (builderMode || preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
        forType = page.forAssessmentType;
      } else {
        forType = section.forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  const resolveDefaultForAssessmentType = () => {
    if (reportForType !== null && reportForType !== undefined) {
      return reportForType;
    }

    if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
      return page.forAssessmentType;
    }

    return section.forAssessmentType !== null ? section.forAssessmentType : undefined;
  };

  const isForSectionAssessmentTypeInputDisabled = () => (reportForType !== null && reportForType !== undefined)
    || (page.forAssessmentType !== null && page.forAssessmentType !== undefined);

  const renderContent = () => {
    if (builderMode) {
      return undefined;
    }

    if (section.content) {
      return (
        <div className="report-section-content wysiwyg">
          {
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={
              {
                __html: DOMPurify.sanitize(section.content, {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: ['allow', 'allowfullscreen', 'mozallowfullscreen', 'webkitallowfullscreen', 'frameborder', 'scrolling'],
                }),
              }
            }
            />
          }
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <div id={`reportSection-${sectionIndex}`} className="report-section">
        <div className="report-section-header">
          <span>
            {section.name}
            {resolveReportType()}
          </span>
          {builderMode && (
            <>
              <span className="text-nowrap">
                <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2" style={{ cursor: 'pointer' }} onClick={() => toggleEditModal()} />
                <FontAwesomeIcon
                  icon={['fas', 'trash-alt']}
                  className="mr-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleDeleteModal()}
                />
                <span {...dragHandleProps}><FontAwesomeIcon icon={['fas', 'arrows-alt']} /></span>
              </span>
              <PromptModal headerText="Delete section" confirmAction={() => deleteSection()} isOpen={deleteModal} toggle={toggleDeleteModal}>
                Are you sure you want to delete this section?
              </PromptModal>
            </>
          )}
        </div>
        {renderContent()}
      </div>
      {builderMode && (
        <Modal
          backdrop="static"
          size="xl"
          isOpen={editModal}
          toggle={toggleEditModal}
        >
          <ModalHeader toggle={toggleEditModal}>
            Edit
            {' '}
            {section?.name}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Label for="sectionName" sm={2}>Title</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="name"
                  id="sectionName"
                  placeholder="Enter section title"
                  value={newSectionName}
                  onChange={(e) => setNewSectionName(e.target.value)}
                />
              </Col>
            </Row>
            {reportAvailableTypes?.length > 0 && (
              <Row className="mt-2">
                <Label for="forSectionAssessmentType" sm={2}>For Type</Label>
                <Col sm={10}>
                  <Input
                    type="select"
                    name="forSectionAssessmentType"
                    id="forSectionAssessmentType"
                    defaultValue={resolveDefaultForAssessmentType()}
                    onChange={(e) => setForType(e.target.value)}
                    disabled={isForSectionAssessmentTypeInputDisabled()}
                  >
                    {reportAvailableTypes.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                      >
                        {item.key}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            )}
            <Row className="mt-2">
              <Col>
                <CKEditor
                  editor={CustomEditor}
                  data={newSectionContent}
                  config={{
                    toolbar: {
                      shouldNotGroupWhenFull: false,
                      items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'fontColor',
                        'fontSize',
                        'removeFormat',
                        '|',
                        'alignment',
                        '|',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'subscript',
                        'superscript',
                      ],
                    },
                    removePlugins: ['MediaEmbedToolbar', 'Base64UploadAdapter'],
                    shouldNotGroupWhenFull: false,
                    extraPlugins: [
                      TextEditorUploadAdapter,
                    ],
                    mediaEmbed: {
                      previewsInData: true,
                    },
                    link: {
                      decorators: {
                        openInNewTab: {
                          mode: 'manual',
                          label: 'Open in a new tab',
                          attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          },
                        },
                      },
                      defaultProtocol: 'https://',
                    },
                    fontColor: {
                      columns: 10,
                      colors: [
                        { color: 'hsl(6, 54%, 95%)', label: ' ' },
                        { color: 'hsl(6, 54%, 89%)', label: ' ' },
                        { color: 'hsl(6, 54%, 78%)', label: ' ' },
                        { color: 'hsl(6, 54%, 68%)', label: ' ' },
                        { color: 'hsl(6, 54%, 57%)', label: ' ' },
                        { color: 'hsl(6, 63%, 46%)', label: ' ' },
                        { color: 'hsl(6, 63%, 41%)', label: ' ' },
                        { color: 'hsl(6, 63%, 35%)', label: ' ' },
                        { color: 'hsl(6, 63%, 29%)', label: ' ' },
                        { color: 'hsl(6, 63%, 24%)', label: ' ' },
                        { color: 'hsl(6, 78%, 96%)', label: ' ' },
                        { color: 'hsl(6, 78%, 91%)', label: ' ' },
                        { color: 'hsl(6, 78%, 83%)', label: ' ' },
                        { color: 'hsl(6, 78%, 74%)', label: ' ' },
                        { color: 'hsl(6, 78%, 66%)', label: ' ' },
                        { color: 'hsl(6, 78%, 57%)', label: ' ' },
                        { color: 'hsl(6, 59%, 50%)', label: ' ' },
                        { color: 'hsl(6, 59%, 43%)', label: ' ' },
                        { color: 'hsl(6, 59%, 37%)', label: ' ' },
                        { color: 'hsl(6, 59%, 30%)', label: ' ' },
                        { color: 'hsl(283, 39%, 95%)', label: ' ' },
                        { color: 'hsl(283, 39%, 91%)', label: ' ' },
                        { color: 'hsl(283, 39%, 81%)', label: ' ' },
                        { color: 'hsl(283, 39%, 72%)', label: ' ' },
                        { color: 'hsl(283, 39%, 63%)', label: ' ' },
                        { color: 'hsl(283, 39%, 53%)', label: ' ' },
                        { color: 'hsl(283, 34%, 47%)', label: ' ' },
                        { color: 'hsl(283, 34%, 40%)', label: ' ' },
                        { color: 'hsl(283, 34%, 34%)', label: ' ' },
                        { color: 'hsl(283, 34%, 28%)', label: ' ' },
                        { color: 'hsl(282, 39%, 95%)', label: ' ' },
                        { color: 'hsl(282, 39%, 89%)', label: ' ' },
                        { color: 'hsl(282, 39%, 79%)', label: ' ' },
                        { color: 'hsl(282, 39%, 68%)', label: ' ' },
                        { color: 'hsl(282, 39%, 58%)', label: ' ' },
                        { color: 'hsl(282, 44%, 47%)', label: ' ' },
                        { color: 'hsl(282, 44%, 42%)', label: ' ' },
                        { color: 'hsl(282, 44%, 36%)', label: ' ' },
                        { color: 'hsl(282, 44%, 30%)', label: ' ' },
                        { color: 'hsl(282, 44%, 25%)', label: ' ' },
                        { color: 'hsl(204, 51%, 94%)', label: ' ' },
                        { color: 'hsl(204, 51%, 89%)', label: ' ' },
                        { color: 'hsl(204, 51%, 78%)', label: ' ' },
                        { color: 'hsl(204, 51%, 67%)', label: ' ' },
                        { color: 'hsl(204, 51%, 55%)', label: ' ' },
                        { color: 'hsl(204, 64%, 44%)', label: ' ' },
                        { color: 'hsl(204, 64%, 39%)', label: ' ' },
                        { color: 'hsl(204, 64%, 34%)', label: ' ' },
                        { color: 'hsl(204, 64%, 28%)', label: ' ' },
                        { color: 'hsl(204, 64%, 23%)', label: ' ' },
                        { color: 'hsl(204, 70%, 95%)', label: ' ' },
                        { color: 'hsl(204, 70%, 91%)', label: ' ' },
                        { color: 'hsl(204, 70%, 81%)', label: ' ' },
                        { color: 'hsl(204, 70%, 72%)', label: ' ' },
                        { color: 'hsl(204, 70%, 63%)', label: ' ' },
                        { color: 'hsl(204, 70%, 53%)', label: ' ' },
                        { color: 'hsl(204, 62%, 47%)', label: ' ' },
                        { color: 'hsl(204, 62%, 40%)', label: ' ' },
                        { color: 'hsl(204, 62%, 34%)', label: ' ' },
                        { color: 'hsl(204, 62%, 28%)', label: ' ' },
                        { color: 'hsl(168, 55%, 94%)', label: ' ' },
                        { color: 'hsl(168, 55%, 88%)', label: ' ' },
                        { color: 'hsl(168, 55%, 77%)', label: ' ' },
                        { color: 'hsl(168, 55%, 65%)', label: ' ' },
                        { color: 'hsl(168, 55%, 54%)', label: ' ' },
                        { color: 'hsl(168, 76%, 42%)', label: ' ' },
                        { color: 'hsl(168, 76%, 37%)', label: ' ' },
                        { color: 'hsl(168, 76%, 32%)', label: ' ' },
                        { color: 'hsl(168, 76%, 27%)', label: ' ' },
                        { color: 'hsl(168, 76%, 22%)', label: ' ' },
                        { color: 'hsl(168, 42%, 94%)', label: ' ' },
                        { color: 'hsl(168, 42%, 87%)', label: ' ' },
                        { color: 'hsl(168, 42%, 74%)', label: ' ' },
                        { color: 'hsl(168, 42%, 61%)', label: ' ' },
                        { color: 'hsl(168, 45%, 49%)', label: ' ' },
                        { color: 'hsl(168, 76%, 36%)', label: ' ' },
                        { color: 'hsl(168, 76%, 31%)', label: ' ' },
                        { color: 'hsl(168, 76%, 27%)', label: ' ' },
                        { color: 'hsl(168, 76%, 23%)', label: ' ' },
                        { color: 'hsl(168, 76%, 19%)', label: ' ' },
                        { color: 'hsl(145, 45%, 94%)', label: ' ' },
                        { color: 'hsl(145, 45%, 88%)', label: ' ' },
                        { color: 'hsl(145, 45%, 77%)', label: ' ' },
                        { color: 'hsl(145, 45%, 65%)', label: ' ' },
                        { color: 'hsl(145, 45%, 53%)', label: ' ' },
                        { color: 'hsl(145, 63%, 42%)', label: ' ' },
                        { color: 'hsl(145, 63%, 37%)', label: ' ' },
                        { color: 'hsl(145, 63%, 32%)', label: ' ' },
                        { color: 'hsl(145, 63%, 27%)', label: ' ' },
                        { color: 'hsl(145, 63%, 22%)', label: ' ' },
                        { color: 'hsl(145, 61%, 95%)', label: ' ' },
                        { color: 'hsl(145, 61%, 90%)', label: ' ' },
                        { color: 'hsl(145, 61%, 80%)', label: ' ' },
                        { color: 'hsl(145, 61%, 69%)', label: ' ' },
                        { color: 'hsl(145, 61%, 59%)', label: ' ' },
                        { color: 'hsl(145, 63%, 49%)', label: ' ' },
                        { color: 'hsl(145, 63%, 43%)', label: ' ' },
                        { color: 'hsl(145, 63%, 37%)', label: ' ' },
                        { color: 'hsl(145, 63%, 31%)', label: ' ' },
                        { color: 'hsl(145, 63%, 25%)', label: ' ' },
                        { color: 'hsl(48, 89%, 95%)', label: ' ' },
                        { color: 'hsl(48, 89%, 90%)', label: ' ' },
                        { color: 'hsl(48, 89%, 80%)', label: ' ' },
                        { color: 'hsl(48, 89%, 70%)', label: ' ' },
                        { color: 'hsl(48, 89%, 60%)', label: ' ' },
                        { color: 'hsl(48, 89%, 50%)', label: ' ' },
                        { color: 'hsl(48, 88%, 44%)', label: ' ' },
                        { color: 'hsl(48, 88%, 38%)', label: ' ' },
                        { color: 'hsl(48, 88%, 32%)', label: ' ' },
                        { color: 'hsl(48, 88%, 26%)', label: ' ' },
                        { color: 'hsl(37, 90%, 95%)', label: ' ' },
                        { color: 'hsl(37, 90%, 90%)', label: ' ' },
                        { color: 'hsl(37, 90%, 80%)', label: ' ' },
                        { color: 'hsl(37, 90%, 71%)', label: ' ' },
                        { color: 'hsl(37, 90%, 61%)', label: ' ' },
                        { color: 'hsl(37, 90%, 51%)', label: ' ' },
                        { color: 'hsl(37, 86%, 45%)', label: ' ' },
                        { color: 'hsl(37, 86%, 39%)', label: ' ' },
                        { color: 'hsl(37, 86%, 33%)', label: ' ' },
                        { color: 'hsl(37, 86%, 27%)', label: ' ' },
                        { color: 'hsl(28, 80%, 95%)', label: ' ' },
                        { color: 'hsl(28, 80%, 90%)', label: ' ' },
                        { color: 'hsl(28, 80%, 81%)', label: ' ' },
                        { color: 'hsl(28, 80%, 71%)', label: ' ' },
                        { color: 'hsl(28, 80%, 61%)', label: ' ' },
                        { color: 'hsl(28, 80%, 52%)', label: ' ' },
                        { color: 'hsl(28, 74%, 46%)', label: ' ' },
                        { color: 'hsl(28, 74%, 39%)', label: ' ' },
                        { color: 'hsl(28, 74%, 33%)', label: ' ' },
                        { color: 'hsl(28, 74%, 27%)', label: ' ' },
                        { color: 'hsl(24, 71%, 94%)', label: ' ' },
                        { color: 'hsl(24, 71%, 88%)', label: ' ' },
                        { color: 'hsl(24, 71%, 77%)', label: ' ' },
                        { color: 'hsl(24, 71%, 65%)', label: ' ' },
                        { color: 'hsl(24, 71%, 53%)', label: ' ' },
                        { color: 'hsl(24, 100%, 41%)', label: ' ' },
                        { color: 'hsl(24, 100%, 36%)', label: ' ' },
                        { color: 'hsl(24, 100%, 31%)', label: ' ' },
                        { color: 'hsl(24, 100%, 26%)', label: ' ' },
                        { color: 'hsl(24, 100%, 22%)', label: ' ' },
                        { color: 'hsl(192, 15%, 99%)', label: ' ' },
                        { color: 'hsl(192, 15%, 99%)', label: ' ' },
                        { color: 'hsl(192, 15%, 97%)', label: ' ' },
                        { color: 'hsl(192, 15%, 96%)', label: ' ' },
                        { color: 'hsl(192, 15%, 95%)', label: ' ' },
                        { color: 'hsl(192, 15%, 94%)', label: ' ' },
                        { color: 'hsl(192, 5%, 82%)', label: ' ' },
                        { color: 'hsl(192, 3%, 71%)', label: ' ' },
                        { color: 'hsl(192, 2%, 60%)', label: ' ' },
                        { color: 'hsl(192, 1%, 49%)', label: ' ' },
                        { color: 'hsl(204, 8%, 98%)', label: ' ' },
                        { color: 'hsl(204, 8%, 95%)', label: ' ' },
                        { color: 'hsl(204, 8%, 90%)', label: ' ' },
                        { color: 'hsl(204, 8%, 86%)', label: ' ' },
                        { color: 'hsl(204, 8%, 81%)', label: ' ' },
                        { color: 'hsl(204, 8%, 76%)', label: ' ' },
                        { color: 'hsl(204, 5%, 67%)', label: ' ' },
                        { color: 'hsl(204, 4%, 58%)', label: ' ' },
                        { color: 'hsl(204, 3%, 49%)', label: ' ' },
                        { color: 'hsl(204, 3%, 40%)', label: ' ' },
                        { color: 'hsl(184, 9%, 96%)', label: ' ' },
                        { color: 'hsl(184, 9%, 92%)', label: ' ' },
                        { color: 'hsl(184, 9%, 85%)', label: ' ' },
                        { color: 'hsl(184, 9%, 77%)', label: ' ' },
                        { color: 'hsl(184, 9%, 69%)', label: ' ' },
                        { color: 'hsl(184, 9%, 62%)', label: ' ' },
                        { color: 'hsl(184, 6%, 54%)', label: ' ' },
                        { color: 'hsl(184, 5%, 47%)', label: ' ' },
                        { color: 'hsl(184, 5%, 40%)', label: ' ' },
                        { color: 'hsl(184, 5%, 32%)', label: ' ' },
                        { color: 'hsl(184, 6%, 95%)', label: ' ' },
                        { color: 'hsl(184, 6%, 91%)', label: ' ' },
                        { color: 'hsl(184, 6%, 81%)', label: ' ' },
                        { color: 'hsl(184, 6%, 72%)', label: ' ' },
                        { color: 'hsl(184, 6%, 62%)', label: ' ' },
                        { color: 'hsl(184, 6%, 53%)', label: ' ' },
                        { color: 'hsl(184, 5%, 46%)', label: ' ' },
                        { color: 'hsl(184, 5%, 40%)', label: ' ' },
                        { color: 'hsl(184, 5%, 34%)', label: ' ' },
                        { color: 'hsl(184, 5%, 27%)', label: ' ' },
                        { color: 'hsl(210, 12%, 93%)', label: ' ' },
                        { color: 'hsl(210, 12%, 86%)', label: ' ' },
                        { color: 'hsl(210, 12%, 71%)', label: ' ' },
                        { color: 'hsl(210, 12%, 57%)', label: ' ' },
                        { color: 'hsl(210, 15%, 43%)', label: ' ' },
                        { color: 'hsl(210, 29%, 29%)', label: ' ' },
                        { color: 'hsl(210, 29%, 25%)', label: ' ' },
                        { color: 'hsl(210, 29%, 22%)', label: ' ' },
                        { color: 'hsl(210, 29%, 18%)', label: ' ' },
                        { color: 'hsl(210, 29%, 15%)', label: ' ' },
                        { color: 'hsl(210, 9%, 92%)', label: ' ' },
                        { color: 'hsl(210, 9%, 85%)', label: ' ' },
                        { color: 'hsl(210, 9%, 70%)', label: ' ' },
                        { color: 'hsl(210, 9%, 55%)', label: ' ' },
                        { color: 'hsl(210, 14%, 39%)', label: ' ' },
                        { color: 'hsl(210, 29%, 24%)', label: ' ' },
                        { color: 'hsl(210, 29%, 21%)', label: ' ' },
                        { color: 'hsl(210, 29%, 18%)', label: ' ' },
                        { color: 'hsl(210, 29%, 16%)', label: ' ' },
                        { color: 'hsl(210, 29%, 13%)', label: ' ' },
                        { color: assessmentConfiguration.accentColor, label: 'Community Top Bar / Accent color' },
                        { color: assessmentConfiguration.primaryButtonColor, label: 'Community Primary Button Color' },
                        { color: assessmentConfiguration.linkColor, label: 'Community Link Color' },
                        { color: assessmentConfiguration.menuHeadingAndIconColor, label: 'Community Icon Color' },
                        { color: 'rgb(0,0,0)', label: 'Black' },
                        { color: 'rgb(255,255,255)', label: 'White' },
                      ],
                    },
                    image: {
                      styles: [
                        'alignLeft', 'alignCenter', 'alignRight',
                      ],
                      resizeOptions: [
                        {
                          name: 'imageResize:original',
                          label: 'Original',
                          value: null,
                        },
                        {
                          name: 'imageResize:75',
                          label: '75%',
                          value: '75',
                        },
                        {
                          name: 'imageResize:50',
                          label: '50%',
                          value: '50',
                        },
                      ],
                      toolbar: [
                        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                        '|',
                        'imageResize',
                        '|',
                        'imageTextAlternative',
                      ],
                    },
                    pasteFromWordRemoveFontStyles: false,
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onBlur={(event: any, editor: any) => {
                    setNewSectionContent(editor.getData());
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => deleteSection()}>Delete Section</Button>
            {' '}
            <Button color="primary" onClick={() => save()}>Save Section</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

TextEditor.defaultProps = {
  dragHandleProps: undefined,
  builderMode: false,
  preview: false,
};

export default TextEditor;
