import React from 'react';
import { Navbar } from 'reactstrap';

interface Props {
  id: string;
  height?: number;
}

const NavbarBase: React.FC<Props> = ({ id, height, children }) => (
  <header>
    <Navbar id={id} className="border-bottom box-shadow bg-white" light fixed="top" style={height ? { minHeight: height } : {}}>
      {children}
    </Navbar>
  </header>
);

NavbarBase.defaultProps = {
  height: undefined,
};

export default NavbarBase;
