import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportPageItem } from 'models/report';
import { Collapse } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  reportPageNumber as reportPageNumberAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
} from 'state/atoms';
import { history } from '../..';

interface Props {
  page: ReportPageItem;
  pageIndex: number;
  pageIsOpened: boolean;
  allowCollapse?: boolean;
  disableNavigation?: boolean;
  preview?: boolean;
}

const ReportTableOfContentsPage: React.FC<Props> = ({
  page, pageIndex, pageIsOpened, allowCollapse, disableNavigation, preview,
}) => {
  const setReportPageNumber = useSetRecoilState(reportPageNumberAtom);
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);
  const [isOpen, setIsOpen] = useState(pageIsOpened);

  const toggle = () => {
    if (!allowCollapse) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const jumpToSection = (sectionIndex: number) => {
    if (disableNavigation) {
      return;
    }

    setReportPageNumber(pageIndex);
    history.push(`#reportSection-${sectionIndex}`);
  };

  const resolveReportType = (forAssessmentType: number | undefined): string | undefined => {
    if (preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
        forType = page.forAssessmentType;
      } else {
        forType = forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  return (
    <div className="report-toc-page">
      <div role="button" className="report-toc-page-item" onClick={() => toggle()}>
        <div>
          <span className="report-toc-page-item-number">{pageIndex + 1}</span>
          <span className="report-toc-page-item-name">
            {page.name}
            {resolveReportType(page.forAssessmentType)}
          </span>
        </div>
        <FontAwesomeIcon icon={['fas', isOpen ? 'chevron-up' : 'chevron-down']} className="report-toc-page-item-icon" />
      </div>
      <Collapse isOpen={isOpen}>
        {page.sections.map((section, sectionIndex) => (
          <div key={section.id} className="report-toc-section">
            <div
              role="button"
              className="report-toc-section-name"
              onClick={() => jumpToSection(sectionIndex)}
            >
              <span>
                {section.name}
                {resolveReportType(section.forAssessmentType)}
              </span>
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};

ReportTableOfContentsPage.defaultProps = {
  allowCollapse: true,
  disableNavigation: false,
  preview: false,
};

export default ReportTableOfContentsPage;
