import React from 'react';
import { Spinner } from 'reactstrap';

const Loader: React.FC = () => (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <Spinner color="primary" />
  </div>
);

export default Loader;
