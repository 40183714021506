import React from 'react';
import { ToastContainer } from 'react-toastify';

const Layout: React.FC = ({ children }) => (
  <>
    <ToastContainer position="bottom-right" />
    {children}
  </>
);

export default Layout;
