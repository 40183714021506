export interface AssessmentQuestionModel {
  id?: number,
  title: string,
  questionType: AssessmentQuestionType,
  leftOptionLabel: string,
  rightOptionLabel: string,
  options?: AssessmentQuestionOptionModel[],
  selectedOptionId?: number,
  selectedOptionIdMost?: number,
  selectedOptionIdLeast?: number,
  active: boolean
}

export interface AssessmentQuestionOptionModel {
  id: number,
  questionId: number,
  value?: string,
  tooltipText?: string
}

export interface AssessmentResponseModel {
  questionId?: number,
  questionOptionId?: number,
}

export enum AssessmentQuestionType {
  AgreementScale = 0,
  Ranking = 1,
  SimilarityScale = 2,
  Amount = 3,
  Dichotomy = 4
}

export class AssessmentQuestion implements AssessmentQuestionModel {
  id?: number = undefined;

  title = '';

  questionType = AssessmentQuestionType.AgreementScale;

  leftOptionLabel = '';

  rightOptionLabel = '';

  selectedOptionId?: number = undefined;

  selectedOptionIdMost?: number = undefined;

  selectedOptionIdLeast?: number = undefined;

  active = false;

  constructor(init?: AssessmentQuestionModel) {
    Object.assign(this, init);
  }
}

export class AssessmentResponse implements AssessmentResponseModel {
  questionId?: number;

  questionOptionId?: number;

  questionOptionIdMost?: number;

  questionOptionIdLeast?: number;

  constructor(init?: AssessmentQuestionModel) {
    this.questionId = init?.id;
    this.questionOptionId = init?.selectedOptionId;
    this.questionOptionIdMost = init?.selectedOptionIdMost;
    this.questionOptionIdLeast = init?.selectedOptionIdLeast;
  }
}
