import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
} from 'state/atoms';
import { ReportPageItem, ReportSectionItem } from 'models/report';
import DOMPurify from 'dompurify';
import AssessmentGenerosityType from 'components/assessment-result/AssessmentGenerosityType';

interface Props {
  page: ReportPageItem;
  section: ReportSectionItem;
  sectionIndex: number;
  builderMode?: boolean;
  preview?: boolean;
}

const GenerosityOverview: React.FC<Props> = ({
  page, section, sectionIndex, builderMode, preview,
}) => {
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);

  const resolveReportType = (): string | undefined => {
    if (builderMode || preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
        forType = page.forAssessmentType;
      } else {
        forType = section.forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  const renderContent = () => {
    if (builderMode) {
      return undefined;
    }

    if (section.content) {
      return (
        <div className="report-section-content wysiwyg">
          {
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content) }} />
          }
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <div id={`reportSection-${sectionIndex}`} className="report-section">
        <div className="report-section-header">
          <span>
            {section.name}
            {resolveReportType()}
          </span>
        </div>
        {!builderMode && (
          <div className="report-section-content">
            <AssessmentGenerosityType />
          </div>
        )}
        <div className="report-section-content">
          {renderContent()}
        </div>
      </div>
    </>
  );
};

GenerosityOverview.defaultProps = {
  builderMode: false,
  preview: false,
};

export default GenerosityOverview;
