import React from 'react';
import { reportType as reportTypeAtom } from 'state/atoms';
import { useRecoilValue } from 'recoil';

const AssessmentGenerosityType: React.FC = () => {
  const reportType = useRecoilValue(reportTypeAtom);

  const getLabelText = (): string | null => {
    let label = '';
    if (reportType.name && reportType.name?.length > 0) {
      switch (reportType.name[0].key.toLowerCase()) {
        case 'd':
          label = 'THE DECISIVE GIVER';
          break;
        case 'i':
          label = 'THE INSPIRED GIVER';
          break;
        case 's':
          label = 'THE SACRIFICIAL GIVER';
          break;
        case 'c':
          label = 'THE CALCULATED GIVER';
          break;
        default:
          break;
      }
    }
    return label;
  };

  return (
    <>
      <div className="assessment-type-name">
        {reportType.name?.map((item) => <span key={`assessment-type-${item.key}`} style={item.value ? { color: item.value } : {}}>{item.key}</span>)}
      </div>
      <div className="assessment-type-label">
        {reportType.name && reportType.name?.length > 0 ? getLabelText() : ''}
      </div>
    </>
  );
};

export default AssessmentGenerosityType;
