import React, { useEffect } from 'react';
import { ReportBuilderTool as ReportBuilderToolModel, ReportBuilderToolType } from 'models/reportBuilderTool';
import { ReportItemTypes } from 'models/reportItemTypes';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportBuilderTools as reportBuilderToolsAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PersonalityAssessmentType } from 'models/personalityAssessmentType';
import ReportBuilderTool from './ReportBuilderTool';

interface Props {
  buttonCallback?: () => void;
}

const ReportBuilderToolbar: React.FC<Props> = ({ buttonCallback }) => {
  const [reportBuilderTools, setReportBuilderTools] = useRecoilState(reportBuilderToolsAtom);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);

  const initReportBuilderTools = () => {
    const tools: ReportBuilderToolModel[] = [
      {
        id: ReportBuilderToolType.TextEditor,
        name: 'Text Editor',
        type: ReportBuilderToolType.TextEditor,
      },
      {
        id: ReportBuilderToolType.AssessmentResult,
        name: 'User Results',
        type: ReportBuilderToolType.AssessmentResult,
      },
    ];

    if (assessmentConfiguration.assessmentType === PersonalityAssessmentType.DISC) {
      tools.push(
        {
          id: ReportBuilderToolType.DCharacteristics,
          name: 'D Characteristics',
          type: ReportBuilderToolType.DCharacteristics,
        },
        {
          id: ReportBuilderToolType.ICharacteristics,
          name: 'I Characteristics',
          type: ReportBuilderToolType.ICharacteristics,
        },
        {
          id: ReportBuilderToolType.SCharacteristics,
          name: 'S Characteristics',
          type: ReportBuilderToolType.SCharacteristics,
        },
        {
          id: ReportBuilderToolType.CCharacteristics,
          name: 'C Characteristics',
          type: ReportBuilderToolType.CCharacteristics,
        },
      );
    }

    setReportBuilderTools(tools);
  };

  useEffect(() => {
    initReportBuilderTools();
  }, [setReportBuilderTools]);

  return (
    <div className="report-builder-toolbar">
      <div className="report-builder-toolbar-header">
        <Button
          outline
          color="secondary"
          size="sm"
          className="report-builder-toolbar-header-button"
          onClick={buttonCallback}
        >
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </Button>
        <span className="report-builder-toolbar-header-text">Toolbar</span>
      </div>
      <div className="report-builder-toolbar-components">
        <div className="report-builder-toolbar-components-heading">
          Page
        </div>
        <Droppable
          droppableId={`tool#${ReportBuilderToolType.Page}`}
          type={ReportItemTypes.PAGE}
          isDropDisabled
        >
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef}>
              <Draggable
                draggableId={`tool#${ReportBuilderToolType.Page}`}
                index={0}
              >
                {(draggableProvided, draggablesnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.dragHandleProps}
                    {...draggableProvided.draggableProps}
                    style={{ ...draggableProvided.draggableProps.style, opacity: draggablesnapshot.isDragging ? '0.4' : '1' }}
                  >
                    <ReportBuilderTool tool={{
                      id: 0,
                      name: 'Page',
                      type: ReportBuilderToolType.Page,
                    }}
                    />
                  </div>
                )}
              </Draggable>
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="report-builder-toolbar-components-heading">
          Page Sections
        </div>
        {reportBuilderTools.map((tool, index) => (
          <Droppable
            key={tool.id}
            droppableId={`tool#${tool.type}`}
            type={ReportItemTypes.SECTION}
            isDropDisabled
          >
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef}>
                <Draggable
                  draggableId={`tool#${tool.type}`}
                  index={index}
                >
                  {(draggableProvided, draggablesnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                      style={{ ...draggableProvided.draggableProps.style, opacity: draggablesnapshot.isDragging ? '0.4' : '1' }}
                    >
                      <ReportBuilderTool tool={tool} />
                    </div>
                  )}
                </Draggable>
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </div>
  );
};

ReportBuilderToolbar.defaultProps = {
  buttonCallback: undefined,
};

export default ReportBuilderToolbar;
