import { AssessmentQuestionModel } from 'models/assessment';
import { AssessmentConfigurationModel } from 'models/assessmentConfiguration';
import { ReportGraphData, ReportPageItem, ReportTypeItem } from 'models/report';
import { PageModel } from 'models/pagedListResult';
import { atom } from 'recoil';
import { ReportBuilderTool } from 'models/reportBuilderTool';
import { KeyValuePair } from 'models/keyValuePair';
import { ChartType } from 'models/chartType';

export const assessments = atom<AssessmentQuestionModel[]>({
  key: 'assessments',
  default: [],
});

export const assessmentsPageModel = atom<PageModel>({
  key: 'assessmentsPageModel',
  default: {},
});

export const assessmentConfiguration = atom<AssessmentConfigurationModel>({
  key: 'assessmentConfiguration',
  default: {
    logoUrl: './logo.jpg',
    returnUrl: '/',
    webUrl: '/',
    isAdminMode: false,
    questionsPerPage: 0,
    isAssessmentCompleted: false,
    chartType: ChartType.None,
    canResume: false,
  },
});

export const reportPages = atom<ReportPageItem[]>({
  key: 'reportPages',
  default: [],
});

export const reportBuilderTools = atom<ReportBuilderTool[]>({
  key: 'reportBuilderTools',
  default: [],
});

export const reportAvailableTypes = atom<KeyValuePair<string, number>[]>({
  key: 'reportAvailableTypes',
  default: [],
});

export const reportPageNumber = atom<number>({
  key: 'reportPageNumber',
  default: 0,
});

export const assessmentPageNumber = atom<number>({
  key: 'assessmentPageNumber',
  default: 0,
});

export const reportType = atom<ReportTypeItem>({
  key: 'reportType',
  default: { label: 'Type', iconPrefix: 'fas', iconName: 'award' },
});

export const reportGraphData = atom<ReportGraphData[]>({
  key: 'reportGraphData',
  default: [
    {
      category: '1',
      tooltipText: 'Type 1 tooltip',
      color: '#4CBF34',
      score: 50,
      maxScore: 100,
      percentage: 50,
    },
    {
      category: '2',
      tooltipText: 'Type 2 tooltip',
      color: '#16A4B8',
      score: 50,
      maxScore: 100,
      percentage: 50,
    },
    {
      category: '3',
      tooltipText: 'Type 3 tooltip',
      color: '#0064BB',
      score: 50,
      maxScore: 100,
      percentage: 50,
    },
    {
      category: '4',
      tooltipText: 'Type 4 tooltip',
      color: '#88D5B2',
      score: 50,
      maxScore: 100,
      percentage: 50,
    },
  ],
});

export const showReportTocLite = atom<boolean>({
  key: 'showReportTocLite',
  default: false,
});

export const showReportTypeLite = atom<boolean>({
  key: 'showReportTypeLite',
  default: false,
});

export const reportForType = atom<number | null | undefined>({
  key: 'reportForType',
  default: null,
});
