import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { assessmentConfiguration as assessmentConfigurationAtom } from 'state/atoms';
import { Link, RouteComponentProps } from 'react-router-dom';
import { localStorage } from 'utils/constants';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import queryString from 'query-string';
import DOMPurify from 'dompurify';
import NavMenu from '../layout/NavMenu';
import Footer from '../layout/Footer';

const Home: React.FC<RouteComponentProps> = ({ location }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const { token } = queryString.parse(location.search);

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');

    if (footer) {
      return footer?.offsetHeight + 24;
    }

    return undefined;
  };

  const getBeginButtonText = () => {
    if (assessmentConfiguration.canResume) {
      return 'Click to Resume';
    }
    return 'Click to Begin';
  };

  useEffect(() => {
    if (token) {
      window.localStorage.setItem(localStorage.JWT_TOKEN, token);
    }
  }, [token]);

  return (
    <>
      {assessmentConfiguration.name
        ? (
          <div className="home-component">
            <NavMenu />
            <Container>
              <div className="home-content">
                <div />
                <div className="home-content-container" style={{ paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
                  <h1 className="assessment-name">{assessmentConfiguration.name}</h1>
                  <h4 className="mt-5">{assessmentConfiguration.description}</h4>
                  {
                    assessmentConfiguration.introduction
                    && <div className="mt-4 pb-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessmentConfiguration.introduction) }} /> // eslint-disable-line react/no-danger
                  }
                  {
                    assessmentConfiguration.embedUrl
                    && (
                      <div className="responsive-video-wrapper">
                        <iframe title="Assessment Video" width="609" height="343" src={assessmentConfiguration.embedUrl} frameBorder="0" allowFullScreen />
                      </div>
                    )
                  }
                </div>
                <div className="home-content-begin">
                  <Button color="primary" size="xxl" tag={Link} to="/assessment">
                    {getBeginButtonText()}
                  </Button>
                  {assessmentConfiguration.timeEstimate
                    && (
                      <>
                        <span className="time-estimate">Time Estimate:</span>
                        <span className="time-estimate">
                          {assessmentConfiguration.timeEstimate}
                          {' Minutes'}
                        </span>
                      </>
                    )}
                </div>
              </div>
            </Container>
            <Footer className="home-page-footer pb-4" border={false}>
              <div className="home-page-footer-container">
                <Button color="primary" size="xl" tag={Link} to="/assessment">
                  {getBeginButtonText()}
                </Button>
                {assessmentConfiguration.timeEstimate
                  && (
                    <>
                      <span className="time-estimate">Time Estimate:</span>
                      <span className="time-estimate">
                        {assessmentConfiguration.timeEstimate}
                        {' Minutes'}
                      </span>
                    </>
                  )}
              </div>
            </Footer>
          </div>
        )
        : <Loader />}
    </>
  );
};

export default Home;
