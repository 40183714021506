import React from 'react';

interface Props {
  className?: string;
  border?: boolean;
}

const Footer: React.FC<Props> = ({ className, border, children }) => {
  const resolveClass = () => {
    let baseClass = 'page-footer';

    if (border) {
      baseClass += ' border bg-light';
    }

    if (className) {
      baseClass += ` ${className}`;
    }

    return baseClass;
  };

  return (
    <footer id="footer" className={resolveClass()}>
      {children}
    </footer>
  );
};

Footer.defaultProps = {
  className: '',
  border: true,
};

export default Footer;
