import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilState } from 'recoil';
import { reportPages as reportPagesAtom } from 'state/atoms';
import { ReportItemTypes } from 'models/reportItemTypes';
import Button from 'components/common/Button';
import { toast } from 'react-toastify';
import Api from 'api/agent';
import ReportSection from '../report/ReportSection';
import ReportPage from '../report/ReportPage';
import { history } from '../..';

interface Props {
  reportId: string;
  addPage: (destIndex: number) => void;
  addSection: (destParentId: number, destIndex: number, toolType: number) => void;
}

const ReportBuilderContainer: React.FC<Props> = ({ reportId, addPage }) => {
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const [loading, setLoading] = useState(false);

  const SaveChanges = (preview = false) => {
    setLoading(true);
    Api.Assessments.saveReportConfig(reportId, reportPages)
      .then((data) => {
        if (data?.pages) {
          setReportPages(data.pages);
        }
        setLoading(false);
        toast.success('Saved successfully!');

        if (preview) {
          history.push('/report-preview');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('An error occurred while saving!');
        }

        if (preview) {
          history.push('/report-preview');
        }
      });
  };

  return (
    <div className="report-builder-container">
      <div className="report-builder-container-header container">
        <h3>Report</h3>
        <div className="report-builder-container-header-buttons">
          <Button
            color="secondary"
            className="text-nowrap"
            disabled={loading}
            onClick={() => SaveChanges()}
          >
            Save Changes
          </Button>
          <Button
            color="secondary"
            className="text-nowrap"
            disabled={loading}
            onClick={() => SaveChanges(true)}
          >
            Preview Report
          </Button>
        </div>
      </div>
      <Droppable droppableId="container#0" type={ReportItemTypes.PAGE}>
        {(pageDraggableProvided, pageDraggableSnapshot) => (
          <div
            className={pageDraggableSnapshot.isDraggingOver ? 'bg-light' : ''}
            ref={pageDraggableProvided.innerRef}
            {...pageDraggableProvided.droppableProps}
          >
            {reportPages.map((page, pageIndex) => (
              <Draggable
                key={page.id}
                draggableId={`page#${page.id}`}
                index={pageIndex}
              >
                {(pageDroppableProvided, pageDroppableSnapshot) => (
                  <div
                    ref={pageDroppableProvided.innerRef}
                    {...pageDroppableProvided.draggableProps}
                    style={{ ...pageDroppableProvided.draggableProps.style, opacity: pageDroppableSnapshot.isDragging ? '0.4' : '1', marginTop: 40 }}
                  >
                    <Droppable
                      key={page.id}
                      droppableId={page.id.toString()}
                      type={ReportItemTypes.SECTION}
                    >
                      {(sectionDroppableProvided, sectionDroppableSnapshot) => (
                        <div
                          className={sectionDroppableSnapshot.isDraggingOver ? 'bg-light' : ''}
                          ref={sectionDroppableProvided.innerRef}
                          {...sectionDroppableProvided.droppableProps}
                        >
                          <ReportPage
                            page={page}
                            index={pageIndex}
                            dragHandleProps={pageDroppableProvided.dragHandleProps}
                            builderMode
                          >
                            {page.sections.map((section, sectionIndex) => (
                              <Draggable
                                key={section.id}
                                draggableId={`section#${section.id}`}
                                index={sectionIndex}
                              >
                                {(sectionDraggableProvided, sectionDraggableSnapshot) => (
                                  <div
                                    ref={sectionDraggableProvided.innerRef}
                                    {...sectionDraggableProvided.draggableProps}
                                    style={{ ...sectionDraggableProvided.draggableProps.style, opacity: sectionDraggableSnapshot.isDragging ? '0.4' : '1' }}
                                  >
                                    <ReportSection
                                      page={page}
                                      section={section}
                                      pageIndex={pageIndex}
                                      sectionIndex={sectionIndex}
                                      dragHandleProps={sectionDraggableProvided.dragHandleProps}
                                      builderMode
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            <div className="report-builder-container-placeholder" style={{ opacity: sectionDroppableSnapshot.isDraggingOver ? '0' : '1' }}>
                              <span role="button">To add a section, drag the section you want to add from the toolbar!</span>
                            </div>
                          </ReportPage>
                          {sectionDroppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            <div className="report-builder-container-placeholder container mt-5" style={{ opacity: pageDraggableSnapshot.isDraggingOver ? '0' : '1' }}>
              <span role="button" onClick={() => addPage(reportPages.length)}>To add a page, either drag the page from the toolbar or click here!</span>
            </div>
            {pageDraggableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default ReportBuilderContainer;
