export interface ReportBuilderTool {
  id: number;
  name: string;
  type: ReportBuilderToolType;
}

export enum ReportBuilderToolType {
  Page = 0,
  TextEditor = 1,
  AssessmentResult = 2,
  // DISC Custom Types
  DCharacteristics = 100,
  ICharacteristics = 101,
  SCharacteristics = 102,
  CCharacteristics = 103,
}
