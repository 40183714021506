import React, { useState } from 'react';
import Button from 'components/common/Button';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

interface Props {
  subject: string;
  deleteAction: () => void;
  disabled?: boolean;
}

const DeletePromptButton: React.FC<Props> = ({
  subject, deleteAction, disabled, children,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button disabled={disabled} onClick={() => toggle()}>
        {children}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`Delete ${subject}`}</ModalHeader>
        <ModalBody>
          {`Are You sure you want to delete this ${subject}?`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
          {' '}
          <Button color="primary" onClick={() => deleteAction()}>Confirm</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

DeletePromptButton.defaultProps = {
  disabled: false,
};

export default DeletePromptButton;
