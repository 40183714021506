import React, { useEffect, useState } from 'react';
import NavMenu from 'components/layout/NavMenu';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Api from 'api/agent';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  assessmentConfiguration as assessmentConfigurationAtom,
  reportPages as reportPagesAtom,
  reportPageNumber as reportPageNumberAtom,
  reportType as reportTypeAtom,
  reportGraphData as reportGraphDataAtom,
} from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import Footer from 'components/layout/Footer';
import AssessmentType from 'components/assessment-result/AssessmentType';
import Confetti from 'react-dom-confetti';
import TooltipComponent from 'components/common/TooltipComponent';
import ReportTocContainer from './ReportTocContainer';
import ReportContainer from './ReportContainer';
import ReportTypeContainer from './ReportTypeContainer';
import ReportNavMenuMobile from './ReportNavMenuMobile';
import { history } from '../..';

interface Props {
  preview?: boolean;
}

const Report: React.FC<Props> = ({ preview }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [reportPageNumber, setReportPageNumber] = useRecoilState(reportPageNumberAtom);
  const setReportType = useSetRecoilState(reportTypeAtom);
  const setReportGraphData = useSetRecoilState(reportGraphDataAtom);
  const [retakeAssessment, setRetakeAssessment] = useState(false);
  const [retakeAssessmentMessage, setRetakeAssessmentMessage] = useState('');
  const [confetti, setConfetti] = useState(false);

  const backToProfile = () => {
    window.location.href = assessmentConfiguration.returnUrl;
  };

  const toggle = () => setModal(!modal);

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');
    return footer?.offsetHeight;
  };

  const previousPage = () => {
    if (reportPageNumber > 0) {
      setReportPageNumber(reportPageNumber - 1);
    }
  };

  const nextPage = () => {
    if (reportPages[reportPageNumber + 1]) {
      setReportPageNumber(reportPageNumber + 1);
    }
  };

  const renderModalHeader = () => {
    if (retakeAssessment) {
      return 'Here are your results.';
    }

    return (
      <div>
        Congratulations!
        <br />
        Here are your results.
      </div>
    );
  };

  const renderModalBody = () => {
    if (retakeAssessment) {
      return (
        <div>
          <div className="assessment-type-container mb-4">
            <div className="assessment-type-label">
              Re-take Pattern
            </div>
            <div className="assessment-type-icon">
              <FontAwesomeIcon icon={['fas', 'repeat']} className="brand-icon" />
            </div>
          </div>
          {
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(retakeAssessmentMessage) }} />
          }
        </div>
      );
    }

    return (
      <div>
        <AssessmentType />
      </div>
    );
  };

  const retakeAssessmentAction = () => history.push('/assessment');

  const viewReportAction = () => setModal(false);

  const takeNextAssessmentAction = () => {
    if (assessmentConfiguration.nextAssessmentUrl) {
      window.location.href = assessmentConfiguration.nextAssessmentUrl;
    }
  };

  useEffect(() => {
    if (preview) {
      setLoading(false);
      return;
    }

    Api.Assessments.complete()
      .then((data) => {
        if (data?.pages) {
          setReportPages(data.pages);
        }
        if (data?.type) {
          setReportType(data.type);
        }
        if (data?.graphData) {
          setReportGraphData(data.graphData);
        }
        setLoading(false);
        setModal(true);
        setConfetti(true);
      })
      .catch((error) => {
        if (error.data?.retakeAssessment) {
          setLoading(false);
          setRetakeAssessmentMessage(error.data.retakeAssessmentMessage);
          setRetakeAssessment(true);
          setModal(true);
        } else if (error.data?.message) {
          toast.error(error.data.message);
        }
      });
  }, [setReportPages, setReportType]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [reportPageNumber]);

  return (
    <div className="report-component">
      {!retakeAssessment
        && (
          <>
            <NavMenu buttonText="Back to Profile" buttonCallback={backToProfile} />
            <div className="navmenu-mobile">
              <ReportNavMenuMobile />
            </div>
          </>
        )}
      {loading
        ? <Loader />
        : (!retakeAssessment
          && (
            <div className="report-container" style={{ paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
              <ReportTocContainer preview={preview} />
              <ReportContainer preview={preview} />
              <ReportTypeContainer />
            </div>
          ))}
      {!retakeAssessment
        && reportPages.length > 1
        && (
          <Footer>
            <div className="footer-container d-flex justify-content-between align-items-center">
              <Button color="secondary" disabled={reportPageNumber === 0} onClick={() => previousPage()}>
                <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2" />
                Back
              </Button>
              <Button color="primary" disabled={!reportPages[reportPageNumber + 1]} onClick={() => nextPage()}>
                Next Page
                <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ml-2" />
              </Button>
            </div>
          </Footer>
        )}
      <Modal isOpen={modal} toggle={retakeAssessment ? undefined : toggle} className="assessment-complete-modal">
        <ModalHeader toggle={retakeAssessment ? undefined : toggle}>
          {renderModalHeader()}
        </ModalHeader>
        <ModalBody>
          {renderModalBody()}
        </ModalBody>
        <ModalFooter>
          {retakeAssessment
            ? (
              <Button color="primary" onClick={() => retakeAssessmentAction()}>Re-take Assessment</Button>
            )
            : (
              <div>
                <Button id="viewMyProfile" color="primary" onClick={() => viewReportAction()}>View My Report</Button>
                <TooltipComponent target="viewMyProfile" placement="right" text="You can access this report later from your profile." />
              </div>
            )}
          {assessmentConfiguration.nextAssessmentUrl
            && (
              <Button color="primary" outline onClick={() => takeNextAssessmentAction()}>Take Next Assessment</Button>
            )}
          <Button color="secondary" outline onClick={() => backToProfile()}>View My Profile</Button>
        </ModalFooter>
        <div className="confetti-container">
          <Confetti
            active={confetti}
            config={{
              angle: 90,
              spread: 50,
              startVelocity: 65,
              elementCount: 200,
              dragFriction: 0.1,
              duration: 5000,
              stagger: 3,
              width: '10px',
              height: '10px',
              colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

Report.defaultProps = {
  preview: false,
};

export default Report;
