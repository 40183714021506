import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportBuilderToolbar from './ReportBuilderToolbar';

const ReportBuilderToolbarContainer: React.FC = () => {
  const [showLite, setShowLite] = useState(false);

  return (
    <div className="report-builder-toolbar-container" style={{ minWidth: showLite ? 50 : 240, maxWidth: showLite ? 50 : 240 }}>
      <div className={`${(showLite ? '' : ' d-none')}`}>
        <div className="report-builder-toolbar-lite">
          <div className="report-builder-toolbar-lite-header">
            <Button
              outline
              color="secondary"
              size="sm"
              className="report-builder-toolbar-lite-header-button"
              onClick={() => setShowLite(false)}
            >
              <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            </Button>
            <FontAwesomeIcon icon={['fas', 'tools']} className="report-builder-toolbar-lite-header-icon brand-primary-bg text-white" />
          </div>
        </div>
      </div>
      <div className={`${(showLite ? ' d-none' : '')}`}>
        <ReportBuilderToolbar buttonCallback={() => setShowLite(true)} />
      </div>
    </div>
  );
};

export default ReportBuilderToolbarContainer;
