import React, { Fragment } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
} from 'state/atoms';
import {
  isNextPageAvailable as isNextPageAvailableSelector,
  currentPageAssessments as currentPageAssessmentsSelector,
} from 'state/selectors';
import { AssessmentQuestion, AssessmentQuestionModel, AssessmentResponse } from 'models/assessment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import TooltipComponent from 'components/common/TooltipComponent';
import Api from 'api/agent';
import { history } from '../..';

interface Props {
  assessmentQuestion: AssessmentQuestionModel;
  optionClickCallback: () => void;
  pageChangedCallback?: () => void;
}

const DichotomyScale: React.FC<Props> = ({ assessmentQuestion, optionClickCallback, pageChangedCallback }) => {
  const [assessments, setAssessments] = useRecoilState(assessmentsAtom);
  const [assessmentPageNumber, setAssessmentPageNumber] = useRecoilState(assessmentPageNumberAtom);
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const isNextPageAvailable = useRecoilValue(isNextPageAvailableSelector);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);

  const setOption = (optionId: number) => {
    optionClickCallback();

    const assessmentQuestionObject = new AssessmentQuestion(assessmentQuestion);
    assessmentQuestionObject.selectedOptionId = optionId;

    const assessmentsNew = currentPageAssessments.map((aq) => {
      if (aq.id === assessmentQuestion.id) {
        return assessmentQuestionObject;
      }

      return aq;
    });

    setAssessments(assessments.map((aq) => {
      if (aq.id === assessmentQuestion.id) {
        return assessmentQuestionObject;
      }

      return aq;
    }));

    Api.Assessments.save(new AssessmentResponse(assessmentQuestionObject))
      .then(() => {
        if (!isNextPageAvailable
          && assessmentsNew.every((a) => a.selectedOptionId)
          && currentPageAssessments.some((a) => !a.selectedOptionId)) {
          history.push('/report');
        }
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        }
      });

    if (isNextPageAvailable && assessmentsNew.every((a) => a.selectedOptionId) && currentPageAssessments.some((a) => !a.selectedOptionId)) {
      if (pageChangedCallback) {
        pageChangedCallback();
      }

      if (assessmentPageNumber === 0 || assessmentConfiguration.questionsPerPage === 1) {
        let timeout = 500;
        if (assessmentPageNumber === 0) {
          timeout = 1500;
        }

        setTimeout(() => {
          setAssessmentPageNumber(assessmentPageNumber + 1);
        }, timeout);
      } else {
        setAssessmentPageNumber(assessmentPageNumber + 1);
      }
    }
  };

  const resolveOptionStatus = (currentOptionId: number) => {
    if (assessmentQuestion.selectedOptionId === currentOptionId) {
      return 'active';
    }

    if (assessmentQuestion.selectedOptionId) {
      return 'inactive';
    }

    return '';
  };

  return (
    <div className="dichotomy-scale-component">
      <div className="dichotomy-scale">
        <span className="dichotomy-scale-question">{assessmentQuestion.title}</span>
        <div className="dichotomy-scale-options">
          {assessmentQuestion.options?.map((option, index) => (
            <Fragment key={option.id}>
              <div className={`dichotomy-scale-option ${resolveOptionStatus(option.id)}`}>
                <div>
                  <span className="dichotomy-scale-option-title">{option.value}</span>
                  {option.tooltipText
                    && (
                      <>
                        <FontAwesomeIcon id={`questionIcon-${option.id}`} className="ml-2" aria-hidden="true" icon={['far', 'question-circle']} />
                        <TooltipComponent text={option.tooltipText} target={`questionIcon-${option.id}`} html bold />
                      </>
                    )}
                </div>
                <div
                  role="radio"
                  aria-checked={assessmentQuestion.selectedOptionId === option.id}
                  className={`dichotomy-scale-option-checkbox ${resolveOptionStatus(option.id)}`}
                  onClick={() => setOption(option.id)}
                >
                  <FontAwesomeIcon aria-hidden="true" icon={['fas', 'check']} />
                </div>
              </div>
              {assessmentQuestion.options && index !== assessmentQuestion.options.length - 1 && <div className="separator" />}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

DichotomyScale.defaultProps = {
  pageChangedCallback: undefined,
};

export default DichotomyScale;
