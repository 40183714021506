import React from 'react';
import { ReportPageItem } from 'models/report';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  reportPageNumber as reportPageNumberAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
} from 'state/atoms';
import { history } from '../..';

interface Props {
  page: ReportPageItem;
  pageIndex: number;
  pageIsOpened: boolean;
  disableNavigation?: boolean;
  preview?: boolean;
}

const ReportTableOfContentsLitePage: React.FC<Props> = ({
  page, pageIndex, pageIsOpened, disableNavigation, preview,
}) => {
  const setReportPageNumber = useSetRecoilState(reportPageNumberAtom);
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);

  const jumpToSection = (sectionIndex: number) => {
    if (disableNavigation) {
      return;
    }
    setReportPageNumber(pageIndex);
    history.push(`#reportSection-${sectionIndex}`);
  };

  const resolveReportType = (forAssessmentType: number | undefined): string | undefined => {
    if (preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
        forType = page.forAssessmentType;
      } else {
        forType = forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  return (
    <div className="report-toc-lite-page">
      <div className={`report-toc-lite-page-item${(pageIsOpened ? ' active' : '')}`}>
        {pageIndex + 1}
      </div>
      <div className="report-toc-lite-page-hover">
        <div className="report-toc-lite-page-content">
          <div className="report-toc-lite-page-content-header">
            <span className="report-toc-lite-page-content-header-number">{pageIndex + 1}</span>
            <span className="report-toc-lite-page-content-header-text">
              {page.name}
              {resolveReportType(page.forAssessmentType)}
            </span>
          </div>
          {page.sections.map((section, sectionIndex) => (
            <div
              key={section.id}
              role="button"
              className="report-toc-lite-page-content-section"
              onClick={() => jumpToSection(sectionIndex)}
            >
              <span>
                {section.name}
                {resolveReportType(section.forAssessmentType)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ReportTableOfContentsLitePage.defaultProps = {
  disableNavigation: false,
  preview: false,
};

export default ReportTableOfContentsLitePage;
