import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
} from 'state/atoms';
import DOMPurify from 'dompurify';
import Assessment from './Assessment';

interface Props {
  optionClickCallback: () => void;
}

const PracticeQuestion: React.FC<Props> = ({ optionClickCallback }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const assessmentPageNumber = useRecoilValue(assessmentPageNumberAtom);
  const assessments = useRecoilValue(assessmentsAtom);

  const [optionClicked, setOptionClicked] = useState(false);

  const onPageChange = () => {
    setOptionClicked(true);
  };

  return (
    <div className="pt-2">
      {
        assessmentConfiguration.practiceIntroduction
          // eslint-disable-next-line react/no-danger
          ? <div className="practice-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessmentConfiguration.practiceIntroduction) }} />
          : (
            <div className="practice-text text-center">
              <h4>Let&apos;s practice first!</h4>
            </div>
          )
      }
      {assessments.length
        && (
          <Assessment
            key={assessments[0].id}
            assessmentQuestion={assessments[0]}
            optionClickCallback={optionClickCallback}
            pageChangedCallback={onPageChange}
          >
            {optionClicked && assessmentPageNumber === 0 && (
              <div className="practice-question-success">
                <span className="fade-in-6">GREAT!</span>
              </div>
            )}
          </Assessment>
        )}
    </div>
  );
};

export default PracticeQuestion;
