import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { assessmentPageNumber as assessmentPageNumberAtom } from 'state/atoms';
import { currentPageAssessments as currentPageAssessmentsSelector } from 'state/selectors';
import PracticeQuestion from './PracticeQuestion';
import Assessment from './Assessment';

interface Props {
  optionClickCallback: () => void;
}

const ScrollableAssessment: React.FC<Props> = ({ optionClickCallback }) => {
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const assessmentPageNumber = useRecoilValue(assessmentPageNumberAtom);

  const getNavbarHeight = () => {
    const navbar = document.getElementById('navbar');
    return navbar?.offsetHeight;
  };

  const getFooterHeight = () => {
    const footer = document.getElementById('footer');
    return footer?.offsetHeight;
  };

  const renderQuestion = () => {
    if (assessmentPageNumber === 0) {
      return (
        <div className="practice">
          <div style={{ paddingTop: getNavbarHeight(), paddingBottom: getFooterHeight() }}>
            <PracticeQuestion optionClickCallback={optionClickCallback} />
          </div>
        </div>
      );
    }

    return (
      <div className="scrollable-content">
        {currentPageAssessments.map((assessmentQuestion) => (
          <Assessment
            key={assessmentQuestion.id}
            assessmentQuestion={assessmentQuestion}
            optionClickCallback={optionClickCallback}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (currentPageAssessments?.length > 1) {
      const scrollToQuestion = currentPageAssessments.find((a) => !a.selectedOptionId);
      const nextQuestion = document.getElementById(`assessment-${scrollToQuestion ? scrollToQuestion.id : currentPageAssessments[0].id}`);
      nextQuestion?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [currentPageAssessments]);

  return (
    <Container>
      <div className="assessments-content">
        {renderQuestion()}
      </div>
    </Container>
  );
};

export default ScrollableAssessment;
