import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { assessmentConfiguration as assessmentConfigurationAtom } from 'state/atoms';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

import * as am4core from '@amcharts/amcharts4/core';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import { localStorage } from 'utils/constants';
import Api from 'api/agent';
import Assessments from 'components/assessment/Assessments';
import Report from 'components/report/Report';
import ReportBuilderList from 'components/report-builder/ReportBuilderList';
import ReportBuilder from 'components/report-builder/ReportBuilder';
import ReportPreview from 'components/report/ReportPreview';
import Layout from './components/layout/Layout';
import Home from './components/home/Home';
import Unauthorized from './components/layout/Unauthorized';
import Forbidden from './components/layout/Forbidden';
import NotFound from './components/layout/NotFound';
import { history } from '.';

library.add(fas, far, fal);

am4core.addLicense('CH269591967');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
DOMPurify.addHook('uponSanitizeElement', (node: any, data) => {
  if (data.tagName === 'iframe') {
    const src: string = node.getAttribute('src') || '';
    if (!src.startsWith('https://www.youtube.com/')
      && !src.startsWith('https://player.vimeo.com/')
      && !src.startsWith('https://vimeo.com/')) {
      node.parentNode.removeChild(node);
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

const App: React.FC = () => {
  const [assessmentConfiguration, setAssessmentConfiguration] = useRecoilState(assessmentConfigurationAtom);

  useEffect(() => {
    if (!assessmentConfiguration.name) {
      Api.Assessments.getConfig()
        .then((data) => {
          setAssessmentConfiguration(data);

          if (data.webUrl) {
            window.localStorage.setItem(localStorage.WEB_URL, data.webUrl);
          }

          if (data.isAdminMode) {
            history.push('/report-builder');
          } else if (data.isAssessmentCompleted) {
            history.push('/report');
          } else if (data.canResume) {
            history.push('/assessment');
          }
        })
        .catch((error) => {
          if (error.data?.message) {
            toast.error(error.data.message);
          }
        });
    }
  }, [assessmentConfiguration.name, setAssessmentConfiguration]);

  const resolveCss = () => {
    let primaryButtonStyle = '';
    if (assessmentConfiguration.primaryButtonColor) {
      primaryButtonStyle = `
        .btn-primary {
          border-color: ${assessmentConfiguration.primaryButtonColor} !important;
          background-color: ${assessmentConfiguration.primaryButtonColor} !important;
        }
      
        .btn-outline-primary {
          background-color: #fff !important;
          border-color: ${assessmentConfiguration.primaryButtonColor} !important;
          color: ${assessmentConfiguration.primaryButtonColor} !important;
        }
      
        .btn-outline-primary:hover {
          background-color: ${assessmentConfiguration.primaryButtonColor} !important;
          color: #fff !important;
        }

        .progress .progress-bar {
            background-color: ${assessmentConfiguration.primaryButtonColor} !important;
        }

        .brand-primary-bg {
          background-color: ${assessmentConfiguration.primaryButtonColor} !important;
        }

        .home-page-footer-container>span {
          color: ${assessmentConfiguration.primaryButtonColor} !important;
        }

        .time-estimate {
          color: ${assessmentConfiguration.primaryButtonColor} !important;
        }
      `;
    }

    let menuHeadingAndIconColor = '';
    if (assessmentConfiguration.menuHeadingAndIconColor) {
      menuHeadingAndIconColor = `
        .brand-icon {
          color: ${assessmentConfiguration.menuHeadingAndIconColor} !important;
        }

        .brand-icon-bg {
          background-color: ${assessmentConfiguration.menuHeadingAndIconColor} !important;
        }
      `;
    }

    return `
      ${primaryButtonStyle}
      ${menuHeadingAndIconColor}
    `;
  };

  return (
    <Layout>
      <style>{resolveCss()}</style>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/assessment" component={Assessments} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/report-builder" component={ReportBuilderList} />
        <Route exact path="/report-builder/:reportId" component={ReportBuilder} />
        <Route exact path="/report-preview" component={ReportPreview} />
        <Route path="/401" component={Unauthorized} />
        <Route path="/403" component={Forbidden} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default App;
