import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportPages as reportPagesAtom,
  reportAvailableTypes as reportAvailableTypesAtom,
  reportForType as reportForTypeAtom,
} from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReportPageItem, ReportSectionItem } from 'models/report';
import {
  Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import Button from 'components/common/Button';
import PromptModal from 'components/common/PromptModal';
import DOMPurify from 'dompurify';

interface Props {
  page: ReportPageItem;
  section: ReportSectionItem;
  pageIndex: number;
  sectionIndex: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  builderMode?: boolean;
  preview?: boolean;
}

const DiscCharacteristics: React.FC<Props> = ({
  page, section, pageIndex, sectionIndex, dragHandleProps, builderMode, preview,
}) => {
  const [reportPages, setReportPages] = useRecoilState(reportPagesAtom);
  const reportAvailableTypes = useRecoilValue(reportAvailableTypesAtom);
  const reportForType = useRecoilValue(reportForTypeAtom);
  const [editModal, setEditModal] = useState(false);
  const [newSectionName, setNewSectionName] = useState(section?.name);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleEditModal = () => setEditModal(!editModal);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const deleteSection = () => {
    const newState = [...reportPages[pageIndex].sections];
    newState.splice(sectionIndex, 1);
    setReportPages(reportPages.map((item, i) => {
      if (i === pageIndex) {
        return { ...item, sections: newState };
      }
      return item;
    }));
  };

  const save = () => {
    const newSectionsState = reportPages[pageIndex].sections
      .map((item, i) => {
        if (i === sectionIndex) {
          return {
            ...item,
            name: newSectionName,
          };
        }
        return item;
      });
    const newPageState = reportPages.map((item, i) => {
      if (i === pageIndex) {
        return { ...item, sections: newSectionsState };
      }
      return item;
    });
    setReportPages(newPageState);
    setEditModal(false);
  };

  const resolveReportType = (): string | undefined => {
    if (builderMode || preview) {
      let forType: number | undefined;
      if (reportForType !== null && reportForType !== undefined) {
        forType = reportForType;
      } else if (page.forAssessmentType !== null && page.forAssessmentType !== undefined) {
        forType = page.forAssessmentType;
      } else {
        forType = section.forAssessmentType;
      }
      const type = reportAvailableTypes.find((x) => x.value === (forType ?? null));
      if (type) {
        return ` (${type.key})`;
      }
    }

    return undefined;
  };

  const renderContent = () => {
    if (builderMode) {
      return undefined;
    }

    if (section.content) {
      return (
        <div className="report-section-content wysiwyg">
          {
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content) }} />
          }
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <div id={`reportSection-${sectionIndex}`} className="report-section">
        <div className="report-section-header">
          <span>
            {section.name}
            {resolveReportType()}
          </span>
          {builderMode && (
            <>
              <span className="text-nowrap">
                <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2" style={{ cursor: 'pointer' }} onClick={() => toggleEditModal()} />
                <FontAwesomeIcon
                  icon={['fas', 'trash-alt']}
                  className="mr-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleDeleteModal()}
                />
                <span {...dragHandleProps}><FontAwesomeIcon icon={['fas', 'arrows-alt']} /></span>
              </span>
              <PromptModal headerText="Delete section" confirmAction={() => deleteSection()} isOpen={deleteModal} toggle={toggleDeleteModal}>
                Are you sure you want to delete this section?
              </PromptModal>
            </>
          )}
        </div>
        {renderContent()}
      </div>
      {builderMode && (
        <Modal backdrop="static" size="lg" isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>
            Edit
            {' '}
            {section?.name}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Label for="sectionName" sm={2}>Title</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="name"
                  id="sectionName"
                  placeholder="Enter section title"
                  value={newSectionName}
                  onChange={(e) => setNewSectionName(e.target.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => deleteSection()}>Delete Section</Button>
            {' '}
            <Button color="primary" onClick={() => save()}>Save Section</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

DiscCharacteristics.defaultProps = {
  dragHandleProps: undefined,
  builderMode: false,
  preview: false,
};

export default DiscCharacteristics;
