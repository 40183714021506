import React, { useEffect } from 'react';
import {
  assessmentConfiguration as assessmentConfigurationAtom,
  reportGraphData as reportGraphDataAtom,
} from 'state/atoms';
import { useRecoilValue } from 'recoil';
import { PersonalityAssessmentType } from 'models/personalityAssessmentType';
import { ChartType } from 'models/chartType';
import { LightenDarkenColor } from 'utils/utils';
import TooltipComponent from 'components/common/TooltipComponent';
import * as am4core from '@amcharts/amcharts4/core';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import * as am4charts from '@amcharts/amcharts4/charts';

am4core.useTheme(am4ThemesAnimated);

interface Props {
  location: string;
  renderChart?: boolean
}

const AssessmentGraph: React.FC<Props> = ({ location, renderChart }) => {
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const reportGraphData = useRecoilValue(reportGraphDataAtom);
  let XYChart: am4charts.XYChart;

  // Enneagram
  const renderEnneagramGraph = () => <div id={`${location}-chart`} style={{ height: 420 }} />;

  const initEnneagramGraph = () => {
    XYChart = am4core.create(`${location}-chart`, am4charts.XYChart);

    XYChart.responsive.enabled = true;
    XYChart.responsive.useDefault = false;
    XYChart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 500) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let state: any;
        if (target instanceof am4charts.Chart) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 10;
          state.properties.paddingRight = 45;
          state.properties.paddingBottom = 5;
          state.properties.paddingLeft = 15;
          return state;
        }

        if (target instanceof am4core.Scrollbar) {
          state = target.states.create(stateId);
          state.properties.marginBottom = -10;
          return state;
        }

        if (target instanceof am4charts.Legend) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          state.properties.marginLeft = 0;
          return state;
        }

        if (target instanceof am4charts.AxisRendererY) {
          state = target.states.create(stateId);
          state.properties.inside = true;
          state.properties.maxLabelPosition = 0.99;
          return state;
        }

        if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
          state = target.states.create(stateId);
          state.properties.dy = -18;
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 5;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 5;

          // Create a separate state for background
          // eslint-disable-next-line no-param-reassign
          target.setStateOnChildren = true;
          const bgstate = target.background.states.create(stateId);
          bgstate.properties.fill = am4core.color('#fff');
          bgstate.properties.fillOpacity = 0.7;

          return state;
        }

        return null;
      },
    });
    XYChart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 320) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let state: any;
        if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererX)) {
          state = target.states.create(stateId);
          state.properties.disabled = true;

          return state;
        }

        return null;
      },
    });

    XYChart.data = reportGraphData;
    XYChart.numberFormatter.numberFormat = '#.';

    const categoryAxis = XYChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = am4core.color('#808080');
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.15;
    categoryAxis.renderer.cellEndLocation = 0.85;
    categoryAxis.renderer.inversed = true;

    const valueAxis = XYChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.strictMinMax = true;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.renderer.grid.template.disabled = true;

    const series = XYChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'percentage';
    series.dataFields.categoryY = 'category';
    series.columns.template.tooltipText = '{tooltipText}';
    if (series.tooltip) {
      series.tooltip.label.wrap = true;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#191919');
      series.tooltip.fontSize = 14;
    }
    series.columns.template.adapter.add('fill', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });
    series.columns.template.adapter.add('stroke', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });

    XYChart.maskBullets = false;
    XYChart.paddingRight = 45;
    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{valueX}%';
    valueLabel.label.horizontalCenter = 'left';
    valueLabel.label.dx = 5;
    valueLabel.label.dy = 1;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    const createRange = (axis: am4charts.ValueAxis, from: number, to: number, label: string) => {
      const range = axis.axisRanges.create();
      range.value = from;
      range.endValue = to;
      range.grid.strokeOpacity = 0;
      range.label.text = label;
      range.label.fill = am4core.color('#808080');
      range.label.fontSize = 14;

      const rangeBorder = axis.axisRanges.create();
      rangeBorder.value = from;
      rangeBorder.grid.strokeOpacity = 0.1;
    };

    createRange(valueAxis, 0, 33.3, 'Unlikely');
    createRange(valueAxis, 33.3, 66.7, 'Possible');
    createRange(valueAxis, 66.7, 100, 'High Potential');
  };
  // Enneagram END

  // MyersBriggs
  const renderMyersBriggsGraph = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < reportGraphData.length; i += 2) {
      const item1 = reportGraphData[i];
      const item2 = reportGraphData[i + 1];
      const percentage = Math.round(item1.percentage);
      rows.push(
        <div key={`row-index-${i}`} className="myers-briggs-chart-row">
          <div className="myers-briggs-chart-percentage">
            <span style={{ fontWeight: 'bold', color: item1.color }}>
              {percentage}
              %
            </span>
            <span>
              {100 - percentage}
              %
            </span>
          </div>
          <div className="myers-briggs-chart-bars">
            <div
              id={`${location}-chart-${item1.assessmentType}`}
              style={{
                height: 16,
                width: `${item1.percentage}%`,
                backgroundImage: `linear-gradient(to right, ${item1.color}, ${LightenDarkenColor(item1.color, 50)})`,
                marginRight: 3,
              }}
            />
            <div
              id={`${location}-chart-${item2.assessmentType}`}
              style={{
                height: 16,
                width: `${item2.percentage}%`,
                backgroundImage: `linear-gradient(to right, ${item2.color}, ${LightenDarkenColor(item2.color, 50)})`,
                marginLeft: 3,
              }}
            />
          </div>
          <div className="myers-briggs-chart-labels">
            <span style={{ fontWeight: 'bold', color: item1.color }}>
              {item1.category}
            </span>
            <span>{item2.category}</span>
          </div>
          <TooltipComponent target={`${location}-chart-${item1.assessmentType}`} text={item1.tooltipText} html textAlign="left" />
          <TooltipComponent target={`${location}-chart-${item2.assessmentType}`} text={item2.tooltipText} html textAlign="left" />
        </div>,
      );
    }

    return (
      <div className="myers-briggs-chart">
        {rows}
      </div>
    );
  };
  // MyersBriggs END

  // DISC
  const renderDISCGraph = () => (
    <div className="d-flex justify-content-center">
      <div id={`${location}-chart`} style={{ height: 350, maxWidth: 350 }} />
    </div>
  );

  const initDISCGraph = () => {
    XYChart = am4core.create(`${location}-chart`, am4charts.XYChart);

    XYChart.responsive.enabled = true;
    XYChart.data = reportGraphData;
    XYChart.numberFormatter.numberFormat = '#.';

    const categoryAxis = XYChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fontSize = 18;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.3;
    categoryAxis.renderer.cellEndLocation = 0.7;

    const valueAxis = XYChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.disabled = true;

    const series = XYChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'percentage';
    series.dataFields.categoryX = 'category';
    series.columns.template.tooltipText = '{tooltipText}';
    if (series.tooltip) {
      series.tooltip.label.wrap = true;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#191919');
      series.tooltip.fontSize = 14;
    }
    series.columns.template.adapter.add('fill', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });
    series.columns.template.adapter.add('stroke', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });

    XYChart.paddingTop = 30;
    XYChart.maskBullets = false;
    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{valueY}%';
    valueLabel.label.dy = -16;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;
  };
  // DISC END

  // SpiritualGifts and SpiritualPreferences
  const renderSpiritualGraph = () => <div id={`${location}-chart`} />;

  const initSpiritualGraph = () => {
    XYChart = am4core.create(`${location}-chart`, am4charts.XYChart);

    XYChart.responsive.enabled = true;
    XYChart.responsive.useDefault = false;
    XYChart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 300) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let state: any;
        if (target instanceof am4charts.Chart) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 10;
          state.properties.paddingRight = 30;
          state.properties.paddingBottom = 5;
          state.properties.paddingLeft = 15;
          return state;
        }

        if (target instanceof am4core.Scrollbar) {
          state = target.states.create(stateId);
          state.properties.marginBottom = -10;
          return state;
        }

        if (target instanceof am4charts.Legend) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          state.properties.marginLeft = 0;
          return state;
        }

        if (target instanceof am4charts.AxisRendererY) {
          state = target.states.create(stateId);
          state.properties.inside = true;
          state.properties.maxLabelPosition = 0.99;
          return state;
        }

        if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
          state = target.states.create(stateId);
          state.properties.dy = -18;
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 5;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 5;

          // Create a separate state for background
          // eslint-disable-next-line no-param-reassign
          target.setStateOnChildren = true;
          const bgstate = target.background.states.create(stateId);
          bgstate.properties.fill = am4core.color('#fff');
          bgstate.properties.fillOpacity = 0.7;

          return state;
        }

        return null;
      },
    });

    XYChart.data = reportGraphData;
    XYChart.numberFormatter.numberFormat = '#.';

    const categoryAxis = XYChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = am4core.color('#808080');
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.15;
    categoryAxis.renderer.cellEndLocation = 0.85;
    categoryAxis.renderer.inversed = true;

    const valueAxis = XYChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.strictMinMax = true;
    valueAxis.min = 0;
    valueAxis.max = 110;
    valueAxis.renderer.grid.template.disabled = true;

    const series = XYChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'percentage';
    series.dataFields.categoryY = 'category';
    series.columns.template.tooltipText = '{tooltipText}';
    if (series.tooltip) {
      series.tooltip.label.wrap = true;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#191919');
      series.tooltip.fontSize = 14;
    }
    series.columns.template.adapter.add('fill', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });
    series.columns.template.adapter.add('stroke', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });

    XYChart.maskBullets = false;
    XYChart.paddingRight = 35;
    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{valueX}%';
    valueLabel.label.horizontalCenter = 'left';
    valueLabel.label.dx = 5;
    valueLabel.label.dy = 1;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110].forEach((range) => {
      const axisRange = valueAxis.axisRanges.create();
      axisRange.value = range;
      axisRange.grid.strokeOpacity = 0.1;
    });

    XYChart.events.on('datavalidated', (ev) => {
      const chart = ev.target;

      if (!chart.svgContainer) {
        return;
      }

      const yAxes = chart.yAxes.getIndex(0);
      const cellSize = 40;

      const adjustHeight = chart.data.length * cellSize - (yAxes?.pixelHeight ?? 0);
      const targetHeight = chart.pixelHeight + adjustHeight;

      chart.svgContainer.htmlElement.style.height = `${targetHeight}px`;
    });
  };
  // SpiritualGifts and SpiritualPreferences END

  const initBarChart = () => {
    XYChart = am4core.create(`${location}-chart`, am4charts.XYChart);

    XYChart.responsive.enabled = true;
    XYChart.responsive.useDefault = false;
    XYChart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 300) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let state: any;
        if (target instanceof am4charts.Chart) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 10;
          state.properties.paddingRight = 30;
          state.properties.paddingBottom = 5;
          state.properties.paddingLeft = 15;
          return state;
        }

        if (target instanceof am4core.Scrollbar) {
          state = target.states.create(stateId);
          state.properties.marginBottom = -10;
          return state;
        }

        if (target instanceof am4charts.Legend) {
          state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          state.properties.marginLeft = 0;
          return state;
        }

        if (target instanceof am4charts.AxisRendererY) {
          state = target.states.create(stateId);
          state.properties.inside = true;
          state.properties.maxLabelPosition = 0.99;
          return state;
        }

        if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
          state = target.states.create(stateId);
          state.properties.dy = -18;
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 5;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 5;

          // Create a separate state for background
          // eslint-disable-next-line no-param-reassign
          target.setStateOnChildren = true;
          const bgstate = target.background.states.create(stateId);
          bgstate.properties.fill = am4core.color('#fff');
          bgstate.properties.fillOpacity = 0.7;

          return state;
        }

        return null;
      },
    });

    XYChart.data = reportGraphData;
    XYChart.numberFormatter.numberFormat = '#.';

    const categoryAxis = XYChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = am4core.color('#808080');
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.15;
    categoryAxis.renderer.cellEndLocation = 0.85;
    categoryAxis.renderer.inversed = true;

    const valueAxis = XYChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.strictMinMax = true;
    valueAxis.min = 0;
    valueAxis.max = 110;
    valueAxis.renderer.grid.template.disabled = true;

    const series = XYChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'percentage';
    series.dataFields.categoryY = 'category';
    series.columns.template.tooltipText = '{tooltipText}';
    if (series.tooltip) {
      series.tooltip.label.wrap = true;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#191919');
      series.tooltip.fontSize = 14;
    }
    series.columns.template.adapter.add('fill', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });
    series.columns.template.adapter.add('stroke', (fill, target) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataContext: any = target.dataItem?.dataContext;
      return dataContext.color;
    });

    XYChart.maskBullets = false;
    XYChart.paddingRight = 35;
    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{valueX}%';
    valueLabel.label.horizontalCenter = 'left';
    valueLabel.label.dx = 5;
    valueLabel.label.dy = 1;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].forEach((range) => {
      const axisRange = valueAxis.axisRanges.create();
      axisRange.value = range;
      axisRange.grid.strokeOpacity = 0.1;
    });

    XYChart.events.on('datavalidated', (ev) => {
      const chart = ev.target;

      if (!chart.svgContainer) {
        return;
      }

      const yAxes = chart.yAxes.getIndex(0);
      const cellSize = 40;

      const adjustHeight = chart.data.length * cellSize - (yAxes?.pixelHeight ?? 0);
      const targetHeight = chart.pixelHeight + adjustHeight;

      chart.svgContainer.htmlElement.style.height = `${targetHeight}px`;
    });
  };

  const initCustomGraph = () => {
    switch (assessmentConfiguration.assessmentType) {
      case PersonalityAssessmentType.Enneagram:
        initEnneagramGraph();
        break;
      case PersonalityAssessmentType.MyersBriggs:
        break;
      case PersonalityAssessmentType.DISC:
        initDISCGraph();
        break;
      case PersonalityAssessmentType.SpiritualGifts:
      case PersonalityAssessmentType.SpiritualPreferences:
        initSpiritualGraph();
        break;
      default:
        initBarChart();
        break;
    }
  };

  const renderCustomGraph = () => {
    switch (assessmentConfiguration.assessmentType) {
      case PersonalityAssessmentType.Enneagram:
        return renderEnneagramGraph();
      case PersonalityAssessmentType.MyersBriggs:
        return renderMyersBriggsGraph();
      case PersonalityAssessmentType.DISC:
        return renderDISCGraph();
      case PersonalityAssessmentType.SpiritualGifts:
      case PersonalityAssessmentType.SpiritualPreferences:
        return renderSpiritualGraph();
      default:
        return <div id={`${location}-chart`} />;
    }
  };

  const renderGraph = () => {
    switch (assessmentConfiguration.chartType) {
      case ChartType.None:
        return <></>;
      case ChartType.Custom:
        return renderCustomGraph();
      default:
        return <div id={`${location}-chart`} />;
    }
  };

  useEffect(() => {
    if (!reportGraphData.length || !renderChart) {
      return undefined;
    }

    switch (assessmentConfiguration.chartType) {
      case ChartType.None:
        return undefined;
      case ChartType.Custom:
        initCustomGraph();
        break;
      default:
        initBarChart();
        break;
    }

    return () => {
      XYChart?.dispose();
    };
  }, [
    reportGraphData,
    renderChart,
    assessmentConfiguration.chartType,
  ]);

  return (
    <div className="assessment-graph">
      {renderGraph()}
    </div>
  );
};

AssessmentGraph.defaultProps = {
  renderChart: true,
};

export default AssessmentGraph;
