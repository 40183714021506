import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import { KeyValuePair } from './keyValuePair';

export interface ReportListData {
  reports: ReportData[];
  availableTypes: KeyValuePair<string, number>[];
}

export interface ReportData {
  id: number;
  forAssessmentType?: number;
  pages?: ReportPageItem[];
  availableTypes: KeyValuePair<string, number>[];
  type?: ReportTypeItem;
  graphData?: ReportGraphData[]
}

export interface ReportPageItem {
  id: number;
  name: string;
  forAssessmentType?: number;
  sections: ReportSectionItem[];
}

export interface ReportSectionItem {
  id: number;
  pageId: number;
  name: string;
  content?: string;
  type: ReportItemType;
  forAssessmentType?: number;
  sequence?: number;
}

export interface ReportTypeItem {
  name?: KeyValuePair<string, string>[];
  label?: string;
  iconPrefix?: IconPrefix;
  iconName?: IconName;
  iconColor?: string;
  videoUrl?: string;
}

export interface ReportGraphData {
  assessmentType?: number;
  category: string;
  tooltipText?: string;
  color?: string;
  score: number;
  maxScore: number;
  percentage: number;
}

export enum ReportItemType {
  TextEditor = 0,
  AssessmentResult = 1,
  // DISC Custom Types
  DCharacteristics = 100,
  ICharacteristics = 101,
  SCharacteristics = 102,
  CCharacteristics = 103,
  GenerosityOverview = 200,
  GenerosityGivingStrengths = 201,
  GenerosityGivingCautions = 202,
  GenerosityGivingExamples = 203,
}
