import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportPageItem } from 'models/report';
import { useSetRecoilState } from 'recoil';
import { reportPageNumber as reportPageNumberAtom } from 'state/atoms';
import { history } from '../..';

interface Props {
  page: ReportPageItem;
  pageIndex: number;
  pageIsOpened: boolean;
  allowCollapse?: boolean;
  pageClickCallback?: () => void;
  sectionClickCallback?: () => void;
}

const ReportTableOfContentsPageMobile: React.FC<Props> = ({
  page, pageIndex, pageIsOpened, allowCollapse, pageClickCallback, sectionClickCallback,
}) => {
  const setReportPageNumber = useSetRecoilState(reportPageNumberAtom);
  const [isOpen, setIsOpen] = useState(false);

  const onPageRowClick = () => {
    if (!allowCollapse) {
      return;
    }
    setIsOpen(!isOpen);
    if (pageClickCallback) {
      pageClickCallback();
    }
  };

  const onSectionRowClick = (sectionIndex: number) => {
    setReportPageNumber(pageIndex);
    history.push(`#reportSection-${sectionIndex}`);
    onPageRowClick();
    if (sectionClickCallback) {
      sectionClickCallback();
    }
  };

  return (
    <div className={`report-toc-page ${pageIsOpened ? '' : 'd-none'}`}>
      <div role="button" className={`report-toc-page-item ${isOpen ? 'expanded' : 'collapsed'}`} onClick={() => onPageRowClick()}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} className="report-toc-page-item-collapse-icon" />
        <div>
          <span className="report-toc-page-item-number">{pageIndex + 1}</span>
          <span className="report-toc-page-item-name">{page.name}</span>
        </div>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className="report-toc-page-item-expand-icon" />
      </div>
      <div className={`${isOpen ? '' : 'd-none'}`}>
        {page.sections.map((section, sectionIndex) => (
          <div key={section.id} className="report-toc-section">
            <div
              role="button"
              className="report-toc-section-name"
              onClick={() => onSectionRowClick(sectionIndex)}
            >
              <span>{section.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ReportTableOfContentsPageMobile.defaultProps = {
  allowCollapse: true,
  pageClickCallback: undefined,
  sectionClickCallback: undefined,
};

export default ReportTableOfContentsPageMobile;
