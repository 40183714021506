import React from 'react';

const Forbidden: React.FC = () => (
  <div className="page-wrap d-flex flex-row align-items-center">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 text-center">
          <span className="display-1 d-block">403</span>
          <div className="mb-4 lead">Forbidden</div>
        </div>
      </div>
    </div>
  </div>
);

export default Forbidden;
