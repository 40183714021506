import React, { useState } from 'react';
import ReportTableOfContents from 'components/report-toc/ReportTableOfContents';
import ReportTableOfContentsLite from 'components/report-toc/ReportTableOfContentsLite';

const ReportBuilderTocContainer: React.FC = () => {
  const [showLite, setShowLite] = useState(false);

  return (
    <div className="report-builder-toc-container" style={{ minWidth: showLite ? 50 : 240, maxWidth: showLite ? 50 : 240 }}>
      <div className={`${(showLite ? '' : ' d-none')}`}>
        <ReportTableOfContentsLite disableNavigation preview buttonCallback={() => setShowLite(false)} />
      </div>
      <div className={`${(showLite ? ' d-none' : '')}`}>
        <ReportTableOfContents disableNavigation preview buttonCallback={() => setShowLite(true)} />
      </div>
    </div>
  );
};

export default ReportBuilderTocContainer;
