/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Api from 'api/agent';

class ImageUploadAdapter {
  public loader: any;

  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload = () => this.loader.file.then((file: any) => Api.Image.upload(file));
}

export default function ImageUploadAdapterPlugin(editor: any): any {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new ImageUploadAdapter(loader);
}
