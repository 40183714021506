import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { reportPages as reportPagesAtom } from 'state/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportTableOfContentsPageMobile from './ReportTableOfContentsPageMobile';

interface Props {
  allowCollapse?: boolean;
  sectionClickCallback?: () => void;
}

const ReportTableOfContentsMobile: React.FC<Props> = ({
  allowCollapse, sectionClickCallback,
}) => {
  const [reportPages] = useRecoilState(reportPagesAtom);
  const [pageNumberExpanded, setPageNumberExpanded] = useState<number | undefined>(undefined);

  const pageClickCallback = (pageIndex: number) => {
    if (pageNumberExpanded === pageIndex) {
      setPageNumberExpanded(undefined);
    } else {
      setPageNumberExpanded(pageIndex);
    }
  };

  return (
    <div className="report-toc">
      <div className="report-toc-header">
        <div>
          <FontAwesomeIcon icon={['fas', 'stream']} className="report-toc-header-icon" />
          <span className="report-toc-header-text">Table of Contents</span>
        </div>
      </div>
      {reportPages && reportPages.map((page, pageIndex) => (
        <ReportTableOfContentsPageMobile
          key={page.id}
          page={page}
          pageIndex={pageIndex}
          pageIsOpened={pageNumberExpanded === undefined || pageNumberExpanded === pageIndex}
          allowCollapse={allowCollapse}
          pageClickCallback={() => pageClickCallback(pageIndex)}
          sectionClickCallback={sectionClickCallback}
        />
      ))}
    </div>
  );
};

ReportTableOfContentsMobile.defaultProps = {
  allowCollapse: true,
  sectionClickCallback: undefined,
};

export default ReportTableOfContentsMobile;
