import React, { useEffect, useState } from 'react';
import { Progress, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  assessments as assessmentsAtom,
  assessmentsPageModel as assessmentsPageModelAtom,
  assessmentConfiguration as assessmentConfigurationAtom,
  assessmentPageNumber as assessmentPageNumberAtom,
} from 'state/atoms';
import {
  assessmentsCompleted as assessmentsCompletedSelector,
  currentPageAssessments as currentPageAssessmentsSelector,
  isNextPageAvailable as isNextPageAvailableSelector,
} from 'state/selectors';
import { AssessmentQuestionModel, AssessmentResponse } from 'models/assessment';
import { PagedListResult } from 'models/pagedListResult';
import { toast } from 'react-toastify';
import Button from 'components/common/Button';
import Api from 'api/agent';
import Loader from 'components/common/Loader';
import NavMenu from '../layout/NavMenu';
import Footer from '../layout/Footer';
import SingularAssessment from './SingularAssessment';
import ScrollableAssessment from './ScrollableAssessment';
import { history } from '../..';

const Assessments: React.FC = () => {
  const [assessments, setAssessments] = useRecoilState(assessmentsAtom);
  const setAssessmentsPageModel = useSetRecoilState(assessmentsPageModelAtom);
  const completed = useRecoilValue(assessmentsCompletedSelector);
  const currentPageAssessments = useRecoilValue(currentPageAssessmentsSelector);
  const isNextPageAvailable = useRecoilValue(isNextPageAvailableSelector);
  const [loading, setLoading] = useState(true);
  const assessmentConfiguration = useRecoilValue(assessmentConfigurationAtom);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [assessmentPageNumber, setAssessmentPageNumber] = useRecoilState(assessmentPageNumberAtom);
  const [pageDirection, setPageDirection] = useState('next');

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (assessments.length) {
      setLoading(false);
    } else if (assessmentConfiguration.questionsPerPage) {
      setLoading(true);
      Api.Assessments.getAll()
        .then((data: PagedListResult<AssessmentQuestionModel>) => {
          if (data.results && data.results.length > 0) {
            for (let index = 0; index < data.results.length; index += 1) {
              // eslint-disable-next-line no-param-reassign
              data.results[index].active = !data.results[index].selectedOptionId;
              if (data.results[index].active) {
                break;
              }
            }
          }
          setAssessments(data.results);
          setAssessmentsPageModel(data.pageInfo);
          setLoading(false);
          if (assessmentConfiguration.canResume) {
            let index = 0;
            for (index = 0; index < data.results.length; index += 1) {
              const element = data.results[index];
              if (!element.selectedOptionId && (!element.selectedOptionIdMost || !element.selectedOptionIdLeast)) {
                break;
              }
            }
            if (index < (data.pageInfo.totalRecords ?? 0)) {
              setAssessmentPageNumber(Math.ceil(index / assessmentConfiguration.questionsPerPage));
            } else if (assessmentConfiguration.questionsPerPage === 1) {
              setAssessmentPageNumber(data.pageInfo.totalRecords ? (data.pageInfo.totalRecords - 1) : 0);
            } else {
              setAssessmentPageNumber(Math.ceil(((data.pageInfo.totalRecords ? data.pageInfo.totalRecords : 1) - 1) / assessmentConfiguration.questionsPerPage));
            }
          }
        })
        .catch((error) => {
          if (error.data?.message) {
            toast.error(error.data.message);
          }
        });
    }
  }, [
    setAssessments,
    setAssessmentsPageModel,
    assessments,
    assessmentConfiguration.questionsPerPage,
  ]);

  const saveAndExit = () => {
    Api.Assessments.saveAll(currentPageAssessments.map((a) => new AssessmentResponse(a)))
      .then(() => {
        window.location.href = assessmentConfiguration.returnUrl;
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        }
      });
  };

  const back = () => {
    setTooltipOpen(false);
    if (assessmentPageNumber === 0) {
      history.push('/');
    } else {
      setPageDirection('prev');
      setAssessmentPageNumber(assessmentPageNumber - 1);
    }
  };

  const nextPage = () => {
    if (isNextPageAvailable && currentPageAssessments.every((a) => a.selectedOptionId || (a.selectedOptionIdMost && a.selectedOptionIdLeast))) {
      setTooltipOpen(false);
      setPageDirection('next');
      setAssessmentPageNumber(assessmentPageNumber + 1);
    } else if (completed >= 100) {
      setTooltipOpen(false);
      history.push('/report');
    } else {
      setTooltipOpen(true);
    }
  };

  const nextPageText = () => {
    if (isNextPageAvailable === false) {
      return 'Complete';
    }
    return 'Next Page';
  };

  const onOptionClick = () => {
    setTooltipOpen(false);
  };

  const getTooltipMessage = () => {
    if (isNextPageAvailable) {
      if (assessmentPageNumber === 0) {
        return 'You need to complete the practice question.';
      }

      return 'You need to complete all questions on this page.';
    }

    return 'You need to complete all questions before a report can be generated.';
  };

  const renderAssessments = () => {
    if (assessmentConfiguration.questionsPerPage > 1) {
      return <ScrollableAssessment optionClickCallback={onOptionClick} />;
    }

    return <SingularAssessment pageDirection={pageDirection} optionClickCallback={onOptionClick} />;
  };

  return (
    <div className="assessments-component">
      <NavMenu buttonText="Save and Exit" buttonCallback={saveAndExit} />
      {loading
        ? <Loader />
        : (
          renderAssessments()
        )}
      <Footer>
        <div className="progress-container progress-container-smaller">
          <Progress className="w-100" value={completed} />
          <span className="progress-text text-nowrap bg-light">
            {completed}
            %
          </span>
        </div>
        <div className="footer-container d-flex justify-content-between align-items-center">
          <Button color="secondary" onClick={() => back()}>
            <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2" />
            Back
          </Button>
          <div className="progress-container progress-container-regular">
            <Progress className="w-100" value={completed} />
            <span className="progress-text text-nowrap bg-light">
              {completed}
              %
            </span>
          </div>
          <Button id="assessmentNextBtn" color="primary" onClick={() => nextPage()}>
            {nextPageText()}
            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ml-2" />
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="assessmentNextBtn"
            toggle={toggleTooltip}
            trigger="manual"
          >
            {getTooltipMessage()}
          </Tooltip>
        </div>
      </Footer>
    </div>
  );
};

export default Assessments;
